import React, { useCallback, useEffect, useRef, useState } from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import Header from './components/Header/header'
import { RallyMap } from './components/RallyMap/rallymap'
import MapLegend from './components/MapLegend/map_legend'
import RallyForm from './components/RallyForm/rallyForm'
import SpeedView from './components/SpeedView/speedView'
import TrackInfo from './components/TrackInfo/trackinfo'
import { getCoordsString } from './utils'
import { Checkbox, ConfigProvider, Modal, Radio } from 'antd'
import TableLegendType from './components/tableLegendType/index'
const packageJson = require('../package.json')
import axios from 'axios'
import ErrorsCard from './components/ParticipantCard/ErrorCard/errorsCard'
import { LegendType } from './components/LegendType/index'
import { useSettings } from './hooks/useSettings'
import { defaultSettings, SettingsContext } from './contexts/settingsContext'
import './app.css'
import MatchTrackAnalyzer from './analyzer/matchTrackAnalyzer/matchTrackAnayzer'
import MatchtrackView from './analyzer/matchTrackAnalyzer/matchTrackView'
import { defaultMapSettings, RallyMapContext } from './contexts/mapContext'
import OppositeAnalyzer from './analyzer/oppositeAnalyzer/oppositeAnalyzer'
import OppositeView from './analyzer/oppositeAnalyzer/oppositeView'
import WaypointsAnalyzer from './analyzer/waypointsAnalyzer/waypointsAnalyzer'
import WaypointsView from './analyzer/waypointsAnalyzer/waypointsView'
import NeutralizationAnalyzer from './analyzer/neutralizationAnalyzer/neutralizationAnalyzer'
import NeutralizationView from './analyzer/neutralizationAnalyzer/neutralizationView'
import SpeedZoneView from './analyzer/speedZoneAnalyzer/speedZoneView'
import SpeedZoneAnalyzer from './analyzer/speedZoneAnalyzer/speedZoneAnalyzer'
import CustomZoneAnalyzer from './analyzer/customZoneAnalyzer/customZoneAnalyzer'
import CustomZoneView from './analyzer/customZoneAnalyzer/customZoneView'
import { SpeedGraphicContext, defaultSpeedGraphicSettings } from './contexts/speedGraphicContext'
import { ReportState } from './constants/formActionEnum'
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TRACK } from './constants/colorConstants'
import GenericSpeedAnalyzer from './analyzer/genericSpeedAnalyzer/genericSpeedAnalyzer'
import GenerciSpeedView from './analyzer/genericSpeedAnalyzer/genericSpeedView'
import { getFiltersBrand, getFiltersBrandWithSection, putFiltersBrand } from './outtrack/rallyrestapi'
import { FilterMode } from './constants/filterModeEnum'
import StatisticsAnalyzer from './analyzer/statisticsAnalyzer/statisticsAnalyzer'
import StatisticsView from './analyzer/statisticsAnalyzer/statisticsView'

const typeCases: Record<number, (value: AreaPoints[], name?: string) => AreaPoints[]> = {
    0: (value: AreaPoints[]) => {
        return []
    },
    1: (value: AreaPoints[], name?: string) => {
        let startFinish = {
            start: '',
            finish: '',
        }
        switch (name) {
            case 'waypoints':
                startFinish = { start: 'DSS', finish: 'ASS' }

                break
            case 'speed_zones':
                startFinish = { start: 'DZ', finish: 'FZ' }
                break
            case 'neutralizations':
                startFinish = { start: 'INEU', finish: 'FNEU' }
                break
            default:
                break
        }
        return value.filter((res: AreaPoints) => res.type === startFinish.start || res.type === startFinish.finish)
    },
    2: (value: AreaPoints[]) => {
        return value
    },
}
const initalTrack: Section = {
    name: '-',
    ut_ini: -1,
    ut_fin: -1,
    section: -1,
    entryCoords: undefined,
    latitude_fin: 0,
    latitude_ini: 0,
    longitude_fin: 0,
    longitude_ini: 0,
    exitCoords: undefined,
    track: [],
}
const URL_LIST_OF_SECTIONS = (rallyId: string, token: string) =>
    `https://rest.anube.es/rallyrest/default/api/sections/${rallyId}.json?token=${token}`
const URL_PARTICIPANTS = (rallyId: string, token: string) =>
    `https://rest.anube.es/rallyrest/default/api/participants_data/${rallyId}.json?token=${token}`
const URL_RALLY_INFO = (rallyId: string, token: string) =>
    `https://rest.anube.es/rallyrest/default/api/rally/${rallyId}.json?token=${token}`
const URL_TRACK = (trackId: number, token: string) =>
    `https://rest.anube.es/rallyrest/default/api/section_path/${trackId}.json?token=${token}&also_hidden=1`
const URL_NEUT_ZONES = (rallyId: string, trackId: number, token: string) =>
    `https://rest.anube.es/rallyrest/default/api/neutralizations/${rallyId}/${trackId}.json?token=${token}`
const URL_SPEED_ZONES = (rallyId: string, trackId: number, token: string) =>
    `https://rest.anube.es/rallyrest/default/api/speed_zones/${rallyId}/${trackId}.json?token=${token}`
const URL_WAYPOINTS = (rallyId: string, trackId: number, token: string) =>
    `https://rest.anube.es/rallyrest/default/api/waypoints/${rallyId}/${trackId}.json?token=${token}`
const getConnections = async (rallyId: number, token: string) => {
    const response = await axios.get(
        `https://rest.anube.es/rallyrest/default/api/devices_connections/${rallyId}.json?token=${token}`
    )
    const res: DevicesConnections = response.data.event.data
    return res
}

const App = () => {
    const [analyzerConfig, setAnalyzerConfig] = useState<any>()
    const [settings, setSettings] = useSettings(defaultSettings)
    const [rallyMap, setRallyMap] = useState(defaultMapSettings)
    const [speedGraphic, setSpeedGraphic] = useState(defaultSpeedGraphicSettings)
    const rallyMapRef = useRef(null)
    const rallyFormRef = useRef(null)
    const [completedPercent, setCompletePercent] = useState<number>(0)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [lastRequestData, setLastRequestData] = useState<DataRallyForm | null>(null)
    const [results, setResults] = useState<Results[]>([])
    const [selectedResult, setSelectedResult] = useState<Results | null>(null)
    const [errorResults, setErrorResults] = useState<AnalyzerResult[]>([])
    const [excluded, setExcluded] = useState<any>([])
    const [useServerFilters, setUseServerFilters] = useState<boolean>(true)
    const [sections, setSections] = useState<SectionsAPI>()
    const [entryCoords, setEntryCoords] = useState<number[]>([0, 0])
    const [exitCoords, setExitCoords] = useState<number[]>([0, 0])
    const [openModalFilters, setOpenModalFilters] = useState<boolean>(false)
    const [openModalFiltersWarning, setOpenModalFiltersWarning] = useState<boolean>(false)
    const [objectFilters, setObjectFilters] = useState<any>({})
    const [filtersMode, setFiltersMode] = useState<FilterMode>(FilterMode.SERVER)
    const [restOfSectionsValue, setRestOfSectionsValue] = useState<boolean>(false)
    const [lastEntryExitCoords, setLastEntryExitCoords] = useState<number>(Date.now())
    const [section, setSection] = useState<Section | null>(null)
    const [participants, setParticipants] = useState<Participant[]>([])
    const [analysisParticipantNo, setAnalysisParticipantNo] = useState<number>(0)
    const [subrallyId, setSubrallyId] = useState<number>(-1)
    const [analysisParticipants, setAnalysisParticipants] = useState<Participant[]>([])
    const [rallyId, setRallyId] = useState<number>(-1)
    const [token, setToken] = useState<string>('')
    const [formVisible, setFormVisible] = useState<boolean>(true)
    const [speedInfoVisible, setSpeedInfoVisible] = useState<boolean>(true)
    const [error, setError] = useState<string>('')
    const [categories, setCategories] = useState<string[]>([])
    const [modeSandbox, setModeSandbox] = useState<boolean>(false)
    const [brandFilters, setBrandFilters] = useState<any>()
    const [classes, setClasses] = useState<string[]>([])
    const [info, setInfo] = useState<RallyAPI>()
    const [resultsState, setResultsState] = useState<ReportState>(ReportState.RS_IDLE)
    const [displayResults, setDisplayResults] = useState<boolean>(false)
    const [mapLoading, setMapLoading] = useState<boolean>(false)
    const [analysisFailed, setAnalysisFailed] = useState<boolean>(false)
    const [detectionType, setDetectionType] = useState<string>('matching')
    const [legendType, setlegendType] = useState<ResultData[]>([])
    const [detailsZoom, setDetailsZom] = useState<boolean>(false)
    const [clickedLegendType, setClickedLegendType] = useState<AreaPoints[]>([])
    const [legendTypeName, setlegendTypeName] = useState<string>('')
    const [prevIndexType, setPrevIndexType] = useState<number | null>(null)
    const [clickCounter, setClickCounter] = useState<number>(1)
    const [children, setChildren] = useState<JSX.Element>(<></>)
    const [_trackCaches, set_trackCaches] = useState<TrackCaches>()
    const analysisParticipantNoRef = useRef(analysisParticipantNo)

    useEffect(() => {
        const fetchFiltersBrand = async () => {
            if (section !== null) {
                const response = await getFiltersBrandWithSection(subrallyId, token, detectionType, section.section)
                const filterBrand = response.data.routes_reports_filters

                if (filterBrand.length > 0) {
                    ;(rallyFormRef.current as any).updateSpecificValues(JSON.parse(filterBrand[0].data))
                }
            }
        }

        fetchFiltersBrand()
    }, [detectionType])

    useEffect(() => {
        if (
            analysisParticipantNoRef.current &&
            errorResults.length + results.length === analysisParticipantNoRef.current
        ) {
            setResultsState(ReportState.RS_IDLE)
        }
    }, [results, errorResults])
    const analyzersConfigDefault: any = {
        matching: {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new MatchTrackAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: MatchTrackResult[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string
            ) => (
                <MatchtrackView
                    setRallyMap={setRallyMap}
                    results={results as MatchTrackResult[]}
                    setSpeedGraphic={setSpeedGraphic}
                />
            ),
        },
        opposite: {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new OppositeAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: OppositeResult[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string,
                routesReports: any
            ) => (
                <OppositeView
                    setRallyMap={setRallyMap}
                    results={results as OppositeResult[]}
                    timezone={timezone}
                    setSpeedGraphic={setSpeedGraphic}
                />
            ),
        },
        waypoints: {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new WaypointsAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: WaypointsResult[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string,
                routesReports: any
            ) => (
                <WaypointsView
                    setRallyMap={setRallyMap}
                    results={results}
                    setSpeedGraphic={setSpeedGraphic}
                    timezone={timezone}
                    onClick={(name: string) => {
                        const refAux = rallyMapRef.current as any
                        refAux.findFeatureByName(name)
                    }}
                    rallyId={subrallyId}
                    token={token}
                />
            ),
        },
        'neut-zones': {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new NeutralizationAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: NeutralizationResult[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string,
                routesReports: any
            ) => (
                <NeutralizationView
                    setRallyMap={setRallyMap}
                    setSpeedGraphic={setSpeedGraphic}
                    results={results as NeutralizationResult[]}
                    timezone={timezone}
                    onClick={(lon: number, lat: number) => {
                        const refAux = rallyMapRef.current as any
                        refAux.updatePointer(lon, lat, true)
                    }}
                />
            ),
        },
        'custom-zone': {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new CustomZoneAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: CustomZoneResult[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string,
                routesReports: any
            ) => (
                <CustomZoneView
                    setRallyMap={setRallyMap}
                    results={results as CustomZoneResult[]}
                    setSpeedGraphic={setSpeedGraphic}
                    timezone={timezone}
                    onClick={(lon: number | null, lat: number | null) => {
                        const refAux = rallyMapRef.current as any
                        refAux.updatePointer(lon, lat, true)
                    }}
                />
            ),
        },
        speed: {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new SpeedZoneAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: SpeedZoneResult[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string,
                routesReports: any
            ) => (
                <SpeedZoneView
                    setRallyMap={setRallyMap}
                    setSpeedGraphic={setSpeedGraphic}
                    results={results as SpeedZoneResult[]}
                    timezone={timezone}
                    onClick={(lon: number, lat: number) => {
                        const refAux = rallyMapRef.current as any
                        refAux.updatePointer(lon, lat, true)
                    }}
                />
            ),
        },
        genericSpeed: {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new GenericSpeedAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: GenericSpeedResult[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string,
                routesReports: any
            ) => (
                <GenerciSpeedView
                    setRallyMap={setRallyMap}
                    setSpeedGraphic={setSpeedGraphic}
                    results={results}
                    timezone={timezone}
                    onClick={(lon: number, lat: number) => {
                        const refAux = rallyMapRef.current as any
                        refAux.updatePointer(lon, lat, true)
                    }}
                />
            ),
        },
        statistics: {
            analyzer: (
                rallyId: number,
                subRallyId: number,
                section: Section,
                newExcluded: any,
                connections: DevicesConnections,
                routesReports: any
            ) => new StatisticsAnalyzer(rallyId, subRallyId, section, newExcluded, connections, routesReports),
            view: (
                results: any[],
                setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
                setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
                timezone: string,
                routesReports: any
            ) => (
                <StatisticsView
                    setRallyMap={setRallyMap}
                    setSpeedGraphic={setSpeedGraphic}
                    results={results as StatisticsResult[]}
                    timezone={timezone}
                />
            ),
        },
        // 'time-in-zone': {
        //     analyzer: (rallyId: number, section: Section, newExcluded: any, connections: Devices) =>
        //         new TimeInZoneAnalyzer(rallyId, section, newExcluded, connections),
        //     view: (
        //         results: TimeInZoneResult[],
        //         setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
        //         setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
        //         timezone: string
        //     ) => (
        //         <TimeInZoneView
        //             setRallyMap={setRallyMap}
        //             results={results}
        //             timezone={timezone}
        //             setSpeedGraphic={setSpeedGraphic}
        //         />
        //     ),
        // },
        // loops: {
        //     analyzer: (rallyId: number, section: Section, newExcluded: any, connections: Devices) =>
        //         new LoopAnalyzer(rallyId, section, newExcluded, connections),
        //     view: (
        //         results: LoopResult[],
        //         setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>,
        //         setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
        //         timezone: string
        //     ) => (
        //         <LoopView
        //             setRallyMap={setRallyMap}
        //             results={results}
        //             timezone={timezone}
        //             setSpeedGraphic={setSpeedGraphic}
        //         />
        //     ),
        // },
    }
    useEffect(() => {
        analysisParticipantNoRef.current = analysisParticipantNo
    }, [analysisParticipantNo])

    useEffect(() => {
        const queryStringParams = _getUrlVars()

        const rallyId: string = queryStringParams['rally_id']
        const token: string = queryStringParams['token']
        if (rallyId) {
            Promise.all([_fetchRallyInfo(rallyId, token)]).then(async values => {
                const rallyInfo = values[0]
                const sections: SectionsAPI = rallyInfo[0] as SectionsAPI
                const participants: Participant[] = rallyInfo[1] as Participant[]
                const info: RallyAPI = rallyInfo[2] as RallyAPI

                const legendType = rallyInfo[3] as ResultData[]
                const categories = Array.from(new Set(participants?.map((e: Participant) => e.categoria))).sort()
                const classes = Array.from(new Set(participants?.map((e: Participant) => e.clase))).sort()

                set_trackCaches(_createFreshCaches(participants))
                setSubrallyId(Number(rallyId))
                setToken(token)
                setSections(sections)
                setParticipants(participants)
                setInfo(info)
                setCategories(categories)
                setClasses(classes)
                setlegendType(legendType)
                setLoaded(true)
            })
        }
    }, [])

    const _fetchRallyInfo = async (rallyId: string, token: string) => {
        const rallyData = [
            axios.get(URL_LIST_OF_SECTIONS(rallyId, token)),
            axios.get(URL_PARTICIPANTS(rallyId, token)),
            axios.get(URL_RALLY_INFO(rallyId, token)),
        ]

        const [sectionsResp, participantsResp, infoResp] = await Promise.all(rallyData)
        const participants: ParticipantsAPI = participantsResp.data.event.data
        const sections: SectionsAPI = sectionsResp.data.event.data
        const info: RallyAPI = infoResp.data.event.data
        const participants_data = participants.participants_data?.filter((e: Participant) => e.participante)

        const data: ResultData[] = []

        const requests = sections.sections.map(async (section: Section) => {
            try {
                const [waypointsResp, speedResp, neutResp] = await Promise.all([
                    axios.get(URL_WAYPOINTS(rallyId, section.section, token)),
                    axios.get(URL_SPEED_ZONES(rallyId, section.section, token)),
                    axios.get(URL_NEUT_ZONES(rallyId, section.section, token)),
                ])

                data.push({
                    analysis: [
                        {
                            type: waypointsResp.data.event.type,
                            data: waypointsResp.data.event.data,
                        },
                        {
                            type: speedResp.data.event.type,
                            data: speedResp.data.event.data,
                        },
                        { type: neutResp.data.event.type, data: neutResp.data.event.data },
                        { type: 'track_files', data: [sections.sections.length] },
                    ],
                    track: section,
                })

                const trackResp = await axios.get(URL_TRACK(section.section, token))
                const tracks: TrackAPI = trackResp.data.event.data
                section.track = tracks.points
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        })

        await Promise.all(requests)

        sections.sections.forEach((e: Section) => {
            e.adminLink = `https://web.anube.es/crono/tramo/${e.section}?`
            e.adminLink += `next_url=/crono/default/tramos/rallies_tbl/${rallyId}`
            e.adminLink += `&rally_id=${rallyId}`
        })

        return [sections, participants_data, info, data]
    }

    const _createFreshCaches = (participants: Participant[]) => {
        const _trackCaches: TrackCaches = {}
        if (_trackCaches) {
            for (const a of Object.keys(_trackCaches)) {
                _trackCaches[a] = _trackCaches[a]
            }
        }
        for (const participant of participants) {
            if (!_trackCaches[participant.id]) {
                _trackCaches[participant.id] = {
                    mainParticipantTrack: {
                        serialNumber: -1,
                        pointRanges: [],
                    },
                    secondaryParticipantTrack: {
                        serialNumber: -1,
                        pointRanges: [],
                    },
                }
            }
        }

        return _trackCaches
    }
    function _getUrlVars() {
        const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')
        const keyValuePairs = hashes.map((hash: string) => hash.split('='))

        return Object.fromEntries(keyValuePairs)
    }

    const pointMarkerInTrack = (point: number[] | undefined) => {
        if (point) {
            const longitude = point[0]
            const latitude = point[1]
            const rallyMapRefAux = rallyMapRef.current as any
            rallyMapRefAux.updatePointer(longitude, latitude, false)
        }
    }

    const onMapUpdated = () => {
        if (mapLoading) {
            setMapLoading(false)
        }
    }

    const handlerOkFilter = async () => {
        switch (filtersMode) {
            case FilterMode.SERVER:
                const refAux = rallyFormRef.current as any
                refAux.updateSpecificValues(objectFilters.jsonBrandFilters)

                break
            case FilterMode.CHANGESERVER:
                setOpenModalFiltersWarning(true)

                break
            case FilterMode.SANDBOX:
                setOpenModalFilters(false)
                setResultsState(ReportState.RS_FETCHING)

                await analyzerConfig!.fetchResults(
                    analysisParticipants,
                    lastRequestData,
                    token,
                    _trackCaches,
                    objectFilters.specificParams,
                    true
                )
                break
        }
        setOpenModalFilters(false)
    }

    const handlerOkFilterWarning = async () => {
        if (restOfSectionsValue) {
            let finded = false
            for (let index = 0; index < sections!.sections.length; index++) {
                const element = sections!.sections[index]
                if (element === section) {
                    finded = true
                }
                if (finded) {
                    await putFiltersBrand(
                        subrallyId,
                        token,
                        detectionType,
                        element.section,
                        objectFilters.specificParams
                    )
                }
            }
        } else {
            await putFiltersBrand(subrallyId, token, detectionType, section!.section, objectFilters.specificParams)
        }
        setOpenModalFiltersWarning(false)
    }

    const onTrackChange = async (sectionId: number | null) => {
        const section = sectionId === null ? null : sections?.sections.find((e: Section) => e.section === sectionId)
        if (section !== undefined) {
            showTrackInfo(section)
            setChildren(<></>)
            setRallyMap(defaultMapSettings)
            setSpeedGraphic(defaultSpeedGraphicSettings)
            if (section !== null) {
                const filterBrand = (await getFiltersBrandWithSection(subrallyId, token, detectionType, sectionId!))
                    .data.routes_reports_filters
                if (filterBrand.length > 0) {
                    ;(rallyFormRef.current as any).updateSpecificValues(JSON.parse(filterBrand[0].data))
                }
            }
        }
    }

    const showTrackInfo = (section: Section | null) => {
        setSection(section)
        setDisplayResults(false)
        setSelectedResult(null)
        setDisplayResults(false)
        setClickedLegendType([])
        setClickCounter(1)
    }

    const cancelDownloads = async () => {
        if (analyzerConfig) {
            analyzerConfig.cancelDownload()
            setResultsState(ReportState.RS_IDLE)
        }
    }

    const submit = async (participants: Participant[], data: DataRallyForm) => {
        setCompletePercent(0)
        setChildren(<></>)
        setRallyMap(defaultMapSettings)
        setSpeedGraphic(defaultSpeedGraphicSettings)
        try {
            await fetchResults(participants, data, true)
        } finally {
            setResultsState(ReportState.RS_IDLE)
        }
    }

    const retryErrorResults = async (participantIds: AnalyzerResult[]) => {
        try {
            const participantsAux = participants.filter(
                (e1: Participant) => participantIds.findIndex((e2: AnalyzerResult) => e2.id === e1.id) !== -1
            )
            if (lastRequestData) {
                await fetchResults(participantsAux, lastRequestData, false)
            }
        } finally {
            setResultsState(ReportState.RS_IDLE)
        }
    }
    const legendTypeClick = (type: string, value: AreaPoints[], color: ColorMap, name: string, index: number) => {
        let clickCounterAux: number = 1
        if (!(prevIndexType !== index)) {
            clickCounterAux = clickCounter + 1
        }
        for (let res of value) {
            if (
                (type === 'speed_zones' || type === 'neutralizations' || type === 'waypoints') &&
                (res.type === 'DZ' || res.type === 'DSS' || res.type === 'INEU')
            ) {
                res.color = color.colorEntry
            } else if (
                (type === 'speed_zones' || type === 'waypoints' || type === 'neutralizations') &&
                (res.type === 'FZ' || res.type === 'ASS' || res.type === 'FNEU')
            ) {
                res.color = color.colorExit
            } else {
                if (color.default) {
                    res.color = color.default
                }
            }
            if (res.longitude && res.latitude) {
                res.coords = [res.longitude, res.latitude]
            }
        }

        const values = typeCases[clickCounterAux % (name === 'waypoints' ? 3 : 2)](value, name)

        setClickCounter(clickCounterAux)
        setlegendTypeName(name)
        setResults([])
        setClickedLegendType(values)
        setSelectedResult(null)
        setDisplayResults(false)
        setPrevIndexType(index)
        setChildren(<></>)
    }

    const onChangeDetailsZoom = (value: boolean) => {
        setDetailsZom(value)
    }

    const changeCoordinates = (entryCoordsAux: number[], exitCoordsAux: number[]) => {
        if (entryCoordsAux !== entryCoords && exitCoordsAux !== exitCoords) {
            setEntryCoords(entryCoordsAux)
            setExitCoords(exitCoordsAux)
            setLastEntryExitCoords(Date.now())
        }
    }

    const handlerChandeRallyId = (rallyId: string) => {
        const newURL = `${window.location.origin}${window.location.pathname}?rally_id=${rallyId}&token=${token}`

        window.history.pushState(null, '', newURL)
        Promise.all([_fetchRallyInfo(rallyId, token)]).then(async values => {
            const rallyInfo = values[0]
            const sections: SectionsAPI = rallyInfo[0] as SectionsAPI
            const participants: Participant[] = rallyInfo[1] as Participant[]
            const info: RallyAPI = rallyInfo[2] as RallyAPI
            const legendType = rallyInfo[3] as ResultData[]
            const categories: string[] = Array.from(new Set(participants.map((e: Participant) => e.categoria))).sort()
            const classes: string[] = Array.from(new Set(participants.map((e: Participant) => e.clase))).sort()
            set_trackCaches(_createFreshCaches(participants))
            setSubrallyId(Number(rallyId))
            setToken(token)
            setSections(sections)
            setParticipants(participants)
            setInfo(info)
            setCategories(categories)
            setClasses(classes)
            setlegendType(legendType)
            setLoaded(true)
            setResults([])
            setDisplayResults(false)
            setSection(null)
            setSelectedResult(null)
            setClickedLegendType([])
        })
        setChildren(<></>)
        setRallyMap(defaultMapSettings)
        setSpeedGraphic(defaultSpeedGraphicSettings)
    }

    const fetchResults = async (participants: Participant[], data: DataRallyForm, clear: boolean) => {
        let newExcluded = excluded
        if (detectionType !== data.detectionType) {
            newExcluded = []
        }
        const initialResults = clear ? [] : results
        const initialSelectedResult = clear ? null : selectedResult

        setSelectedResult(initialSelectedResult)
        setResults(initialResults)
        setAnalysisParticipantNo(clear ? participants.length : analysisParticipantNo)
        setAnalysisParticipants(participants)
        setDisplayResults(true)
        setLastRequestData(data)
        setDetectionType(data.detectionType)
        setResultsState(ReportState.RS_FETCHING)
        setExcluded(newExcluded)
        setError('')
        setErrorResults([])
        setAnalysisFailed(false)
        let section = sections?.sections.find((e: Section) => e.section == data.track)
        let sectionId = section?.section
        if (sectionId && section) {
            try {
                const response = await axios.get(URL_TRACK(sectionId, token))
                section.track = response.data.event.data.points
            } catch {
                setError('Se ha producido un error durante la descarga del recorrido teórico.')
                setResultsState(ReportState.RS_CANCELING)

                return
            }
        }
        let brandFilters = {}
        let allBrandFilters = {}
        try {
            brandFilters = (await getFiltersBrandWithSection(subrallyId, token, data.detectionType, section!.section))
                .data.routes_reports_filters
            allBrandFilters = (await getFiltersBrand(subrallyId, token, data.detectionType)).data.routes_reports_filters
        } catch {
            setError('La brand es diferente a la BD.')
            setResultsState(ReportState.RS_CANCELING)

            return
        }
        setBrandFilters(brandFilters)
        let connections: DevicesConnections = {}
        if (data.showConnections) {
            try {
                connections = await getConnections(subrallyId, token)
            } catch {
                setError('Se ha producido un error durante la descarga de las conexiones de los localizadores.')
                setResultsState(ReportState.RS_CANCELING)

                return
            }
        }
        const config = analyzersConfigDefault[data.detectionType]
        if (config) {
            const analyzerInstance = config.analyzer(info?.rally_id, subrallyId, section!, newExcluded, connections)
            analyzerInstance.onResults = (results: Results[]) => {
                const currentAnalysisParticipantNo = analysisParticipantNoRef.current
                setCompletePercent(100 * (results.length / currentAnalysisParticipantNo))
                setChildren(config.view(results, setSpeedGraphic, setRallyMap, info?.timezone))
                setResults(results)
            }
            analyzerInstance.onErrors = (errorResults: AnalyzerResult[]) => {
                const currentAnalysisParticipantNo = analysisParticipantNoRef.current

                setErrorResults(errorResults)
            }
            analyzerInstance.onResultError = () => {
                setError('Error en el servidor. Es probable que no tenga datos del tramo indicado.')
                setResultsState(ReportState.RS_CANCELING)
                setAnalysisFailed(true)
            }
            analyzerInstance.onExcludedWaypointRanges = (excludedWaypointRanges: any) => {
                setExcluded(excludedWaypointRanges)
            }
            analyzerInstance.onExcludedWaypointIndexes = (excludedWaypointIndexes: any) => {
                setExcluded(excludedWaypointIndexes)
            }
            analyzerInstance.onCacheUpdate = (participantId: number, newCache: TrackCache) => {
                if (_trackCaches) {
                    _trackCaches[participantId] = newCache
                }
            }
            analyzerInstance.createWorkers(participants, data.workers)
            setAnalyzerConfig(analyzerInstance)
            const result = await analyzerInstance.fetchResults(
                participants,
                data,
                token,
                _trackCaches,
                brandFilters,
                false
            )
            if (result) {
                setUseServerFilters(
                    Object.values(result.specificParams).length !== Object.values(result.jsonBrandFilters).length
                )

                setObjectFilters(result)
                setOpenModalFilters(true)
                setResultsState(ReportState.RS_CANCELING)
            }
        }

        if (selectedResult !== null && results !== null) {
            const updateResults = results.find((e: Results) => e?.id === selectedResult?.id)
            if (updateResults !== undefined) {
                setSelectedResult(updateResults)
            }
        }

        if (selectedResult !== null && results !== null) {
            const updateResults = results.find((e: Results) => e?.id === selectedResult?.id)
            if (updateResults !== undefined) {
                setSelectedResult(updateResults)
            }
        }
    }

    if (window.location.pathname == '/version') {
        return <div>Version: {packageJson.version}</div>
    }
    if (!loaded) {
        return <div> Loading...</div>
    }

    const lastData = lastRequestData

    let legendData: LegendData[] = [
        {
            label: 'Official track',
            value: '',
            color: COLOR_TRACK,
            bar: true,
        },
    ]
    if (!info) {
        return <div />
    }

    const propsHeader = {
        name: info.nombre,
        rallyId: subrallyId,
        other_subrallies: info.other_subrallies,
        legendType: legendType,
        sections: sections,
        token: token,
        changeId: handlerChandeRallyId,
        legendTypeClick: legendTypeClick,
        timezone: info.timezone,
        loading: resultsState === ReportState.RS_FETCHING || resultsState === ReportState.RS_CANCELING,
        onToggleFormVisibility: () => {
            setFormVisible(!formVisible)
        },
        onToggleSpeedInfoVisibility: () => {
            setSpeedInfoVisible(!speedInfoVisible)
            const refAux = rallyMapRef.current as any
            refAux.updateSize()
        },
        settings: settings,
        setSettings: setSettings,
    }

    const propsSpeedView = {
        visible: speedInfoVisible,
        colors: [COLOR_SECONDARY, COLOR_PRIMARY],
        timezone: info.timezone,
        onPoint: pointMarkerInTrack,
    }

    const propsRallyMap = {
        loading: mapLoading,
        dataKey: selectedResult === null ? -1 : selectedResult.id,
        organizerTracks:
            section === null ? sections!.sections.filter((e: Section) => typeof e.points !== 'string') : [section],
        rally: subrallyId,
        type: legendTypeName,
        detailsZoom: detailsZoom,
        showConnections: lastData !== null && lastData.showConnections,
        clickCounter: clickCounter,
        timezone: info.timezone,
        rallyLongitude: info.longitud,
        rallyLatitude: info.latitud,
        onMapUpdated: onMapUpdated,
        ref: rallyMapRef,
    }
    return (
        <SettingsContext.Provider value={settings}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#e10818',
                        colorBgElevated: '#f5f5f5',
                    },
                    components: {
                        Select: {
                            colorBgContainerDisabled: 'rgb(255, 255, 255)',
                        },
                        Dropdown: {
                            controlItemBgHover: 'rgba(225, 8, 24, 0.1)',
                        },
                        Collapse: {
                            contentBg: 'rgb(127, 255, 212)',
                            headerBg: 'rgba(255, 255, 255, 0)',
                            contentPadding: '0px 16px',
                            headerPadding: '0px 5px',
                            padding: 1,
                            marginLG: 1,
                        },
                        Form: {
                            marginLG: 5,
                        },
                    },
                }}
            >
                <div
                    className={`grid-container ${
                        speedInfoVisible ? 'grid-container-with-speedinfo' : 'grid-container-without-speedinfo'
                    } bg-neutral-100`}
                >
                    <Header {...propsHeader}></Header>

                    <SpeedGraphicContext.Provider value={speedGraphic}>
                        <SpeedView {...propsSpeedView} />
                    </SpeedGraphicContext.Provider>

                    <RallyMapContext.Provider value={rallyMap}>
                        <RallyMap {...propsRallyMap} />
                    </RallyMapContext.Provider>

                    <MapLegend className="map-legend" values={legendData} />

                    <div className="data grid h-screen w-full grid-cols-3 border border-solid border-neutral-300 bg-neutral-100 ">
                        <div className="relative col-span-2 h-full w-full  overflow-y-auto overflow-x-hidden">
                            {error && (
                                <div className="error-message">
                                    <p>{error}</p>
                                    <span className="error-message-x-button" onClick={() => setError('')}>
                                        X
                                    </span>
                                </div>
                            )}
                            {displayResults && completedPercent < 100 && (
                                <div className="results-progressbar-container w-[96%] absolute z-50">
                                    <ProgressBar
                                        completed={completedPercent}
                                        customLabel={completedPercent.toFixed(0) + '%'}
                                        bgColor={
                                            analysisFailed
                                                ? '#ff0000'
                                                : resultsState === ReportState.RS_FETCHING
                                                  ? '#0fc11b'
                                                  : '#2bb9f0'
                                        }
                                        transitionDuration="0.1s"
                                    />
                                </div>
                            )}
                            {displayResults && (
                                <ErrorsCard
                                    errorResults={errorResults}
                                    disabled={resultsState !== ReportState.RS_IDLE}
                                    onRetry={retryErrorResults}
                                />
                            )}
                            {children}
                            {!displayResults && (
                                <>
                                    <TrackInfo className="" track={section} rallyId={subrallyId} />

                                    <div>
                                        {section !== null && (
                                            <>
                                                <div style={{ paddingTop: 10 }}>
                                                    <LegendType
                                                        section={legendType.find(
                                                            (res: ResultData) => res.track.section === section.section
                                                        )}
                                                        legendTypeClick={legendTypeClick}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div>
                                        <TableLegendType
                                            data={clickedLegendType}
                                            type={legendTypeName}
                                            setCoordinates={changeCoordinates}
                                            clickCounter={clickCounter}
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        <RallyForm
                            tracks={[initalTrack, ...sections?.sections!].map((e: Section) => ({
                                ut_ini: e.name === '-' ? -1 : e.ut_ini,
                                ut_fin: e.name === '-' ? -1 : e.ut_fin,
                                section: e.name === '-' ? -1 : e.section,
                                name: e.name,
                                entryCoords:
                                    e.track === undefined || !e.track.length
                                        ? '0, 0'
                                        : e.latitude_ini && e.longitude_ini
                                          ? getCoordsString(e.latitude_ini, e.longitude_ini)
                                          : getCoordsString(e.track[0][1], e.track[0][0]),
                                exitCoords:
                                    e.track === undefined || !e.track.length
                                        ? '0, 0'
                                        : e.latitude_fin && e.longitude_fin
                                          ? getCoordsString(e.latitude_fin, e.longitude_fin)
                                          : getCoordsString(
                                                e.track[e.track.length - 1][1],
                                                e.track[e.track.length - 1][0]
                                            ),
                            }))}
                            legendType={
                                legendType.find((res: ResultData) => {
                                    if (section !== null) {
                                        return res.track.section === section.section
                                    }
                                })!
                            }
                            participants={participants}
                            rallyId={subrallyId}
                            categories={categories}
                            classes={classes}
                            timezone={info.timezone}
                            onTrackChange={onTrackChange}
                            onChangeDetailsZoom={onChangeDetailsZoom}
                            onSubmit={submit}
                            onTypeChange={(value: string) => {
                                setDetectionType(value)
                            }}
                            onCancel={cancelDownloads}
                            resultsState={resultsState}
                            ref={rallyFormRef}
                        />
                    </div>
                </div>
                <Modal
                    open={openModalFilters}
                    title="Apply filters"
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText="Confirm"
                    onOk={handlerOkFilter}
                    onCancel={() => setOpenModalFilters(false)}
                >
                    <div className="flex flex-col space-y-2">
                        <span>Yours filters are different from the server filters!!!</span>
                        <Radio.Group
                            onChange={e => {
                                setFiltersMode(e.target.value)
                            }}
                            value={filtersMode}
                        >
                            {/* {JSON.stringify(objectFilters.jsonBrandFilters, null, '\t')} */}
                            <Radio value={FilterMode.SERVER} disabled={useServerFilters}>
                                Use server filters
                            </Radio>
                            <Radio value={FilterMode.CHANGESERVER}>Keep yours and upload</Radio>
                            <Radio value={FilterMode.SANDBOX}>Keep yours and not upload (Sandbox)</Radio>
                            {/* {JSON.stringify(objectFilters.specificParams, null, '\t')} */}
                        </Radio.Group>
                    </div>
                </Modal>
                <Modal
                    open={openModalFiltersWarning}
                    title="Apply filters"
                    okText="Confirm and upload"
                    onOk={handlerOkFilterWarning}
                    onCancel={() => setOpenModalFiltersWarning(false)}
                >
                    <div className="flex flex-col space-y-2">
                        <span className="font-bold">This will change everyone's filters!!!!!!!!!!!!!!!</span>
                        <span></span>
                        <Checkbox
                            onChange={e => {
                                setRestOfSectionsValue(e.target.value)
                            }}
                            value={restOfSectionsValue}
                        >
                            Also apply for the next sections.
                        </Checkbox>
                    </div>
                </Modal>
            </ConfigProvider>
        </SettingsContext.Provider>
    )
}

export default App
