import React  from 'react';


interface Props {
    icon: any; 
    onClick: () => void;
}
const ActionButton = ({icon , onClick }: Props) => {
    const className = 'ol-control';
    const Icon =icon
    return (
        <div className={className}> 
            <button 
                type="button"
                onClick={ onClick }
                
            >
                <Icon></Icon>
            </button>
        </div>
    );

}

export default ActionButton;