import React, { Component, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import 'moment-timezone'

import './speedChart.css'

import { isEqual, clone } from 'lodash'
import Dygraph, { dygraphs } from 'dygraphs'

interface Props {
    tracks?: number[][][]
    speedRanges?: SpeedRanges[][]
    colors: string[]
    timezone: string
    onPoint: (point: number[] | undefined) => void
}
const SpeedChart = ({ colors, onPoint, speedRanges, timezone, tracks }: Props) => {
    const [chart, setChart] = useState<Dygraph>()
    const _createSpeedArray = (track: number[][]) => {
        let speeds = []
        for (let i = 0; i < track.length - 1; i++) {
            speeds.push([track[i][2], Number(track[i][3].toFixed(1))])
        }

        return speeds
    }

    const _drawSpeedRange = (
        speedRanges: SpeedRanges[],
        canvas: CanvasRenderingContext2D,
        area: Readonly<dygraphs.Area>,
        g: Readonly<Dygraph>
    ) => {
        speedRanges.forEach((speedRange: SpeedRanges) => {
            let areaBottom = g.toDomYCoord(0)
            let areaTop = g.toDomYCoord(speedRange.speed!)
            let areaLeft = g.toDomXCoord(speedRange.fromTimestamp * 1000)
            let areaRight = g.toDomXCoord(speedRange.toTimestamp * 1000)
            canvas.fillStyle = 'rgba(0, 255, 0, 1.0)'
            canvas.fillRect(areaLeft, areaTop, areaRight - areaLeft, areaBottom - areaTop)

            if (speedRange.maxUnpenalizableSpeed > speedRange.speed!) {
                let penaltyTolerance = speedRange.maxUnpenalizableSpeed - speedRange.speed!
                areaBottom = areaTop + 1
                areaTop = g.toDomYCoord(speedRange.maxUnpenalizableSpeed)
                canvas.fillStyle = 'rgba(255, 255, 0, 1.0)'
                canvas.fillRect(areaLeft, areaTop, areaRight - areaLeft, areaBottom - areaTop)
            }

            canvas.fillStyle = 'rgba(0, 0, 255, 1.0)'
            let leftLinePos = g.toDomXCoord(speedRange.fromTimestamp * 1000)
            let rightLinePos = g.toDomXCoord(speedRange.toTimestamp * 1000)
            canvas.fillRect(leftLinePos, 0, 1, area.h)
            canvas.fillRect(rightLinePos, 0, 1, area.h)
        })
    }

    const updateChart = () => {
        if (tracks) {
            if (tracks.length > 0) {
                let newTracks = clone(tracks)
                newTracks.reverse()
                if (newTracks[0].length > 0 || newTracks[1].length > 0) {
                    const tracksSpeeds = newTracks.map((track: number[][]) => _createSpeedArray(track || []))
                    const tracksSpeedsNonempty = tracksSpeeds.filter((e: number[][]) => e.length !== 0)
                    const minTimestamp = Math.min(...tracksSpeedsNonempty.map((e: number[][]) => e[0][0]))
                    const maxTimestamp = Math.max(...tracksSpeedsNonempty.map((e: number[][]) => e[e.length - 1][0]))

                    let csvData = Array.from(
                        {
                            length: maxTimestamp - minTimestamp + 1,
                        },
                        (v, k) => [k + minTimestamp, '', '']
                    )

                    for (let trackIndex = 0; trackIndex < tracksSpeeds.length; trackIndex++) {
                        for (let speedEntry of tracksSpeeds[trackIndex]) {
                            csvData[speedEntry[0] - minTimestamp][trackIndex + 1] = speedEntry[1]
                        }
                    }

                    csvData = [
                        [
                            'Time',
                            ...newTracks.map((track: number[][], i: number) => (i === 0 ? 'Secondary' : 'Primary')),
                        ],
                        ...csvData.map((speeds: (string | number)[]) => [
                            (speeds[0] as number) * 1000,
                            speeds[1],
                            speeds[2],
                        ]),
                    ]

                    chart?.updateOptions({
                        file: csvData.join('\n'),
                        highlightCallback: highlightHandler,
                        showRangeSelector: true,
                        underlayCallback: (
                            canvas: CanvasRenderingContext2D,
                            area: Readonly<dygraphs.Area>,
                            g: Readonly<Dygraph>
                        ) => {
                            if (speedRanges) {
                                if (speedRanges.length > 1) {
                                    speedRanges.forEach((speedRange: SpeedRanges[]) => {
                                        _drawSpeedRange(speedRange, canvas, area, g)
                                    })
                                }
                            }
                        },
                    })
                }
            } else {
                chart?.updateOptions({ file: [] })
            }
        }
    }
    const highlightHandler = (
        e: MouseEvent,
        xval: number,
        pts: readonly dygraphs.Point[],
        row: number,
        seriesName: string
    ) => {
        const series = pts.find((item: dygraphs.Point) => !isNaN(item.yval!))

        if (series !== undefined) {
            let index = 0

            if (series.name === 'Secondary') {
                index = 1
            }
            if (tracks) {
                onPoint(tracks[index].find(e => e[2] === series.xval! / 1000))
            }
        }
    }
    useEffect(() => {
        let dataTemp: dygraphs.Data = ',\n0,0'

        let speedChart = document.querySelector('.speed-chart')
        const newChart = new Dygraph(speedChart as HTMLElement, dataTemp, {
            customBars: false,
            legend: 'never',
            title: '',

            ylabel: '',
            axes: {
                x: {
                    axisLabelFormatter: (x: moment.MomentInput) => {
                        let formattedTime

                        try {
                            formattedTime = moment(x).tz(timezone).format('HH:mm:ss')
                        } catch (e) {
                            console.log('WARNING: timezone not being taken into consideration on axisLabelFormatter')
                            formattedTime = moment(x).format('HH:mm:ss')
                        }

                        return formattedTime
                    },
                },
            },
            connectSeparatedPoints: true,
            labelsSeparateLines: true,
            colors: colors,
            highlightCallback: () => {},
            underlayCallback: () => {},
        })
        setChart(newChart)
    }, [])

    useEffect(() => {
        updateChart()
    }, [tracks])
    return <div className={`speed-chart h-full w-full bg-neutral-100`}> </div>
}

export default SpeedChart
