import axios from 'axios'

export function getParticipantTrack(
    rally: number,
    participant: number,
    from: number,
    to: number,
    token: string,
    type_of_track: string
) {
    return axios({
        method: 'GET',
        url: 'https://rest.anube.es/rallyrest/default/api/participants_routes.json',
        params: {
            token: token,
            gps: type_of_track,
            ut_ini: from,
            ut_fin: to,
            decimate: 0,
            participants: participant,
            num_fields: 5,
            subrally_id: rally,
        },
    })
}

export function getFiltersBrandWithSection(rallyId: number, token: string, brand: string, trackId: number) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/routes_reports_filters/${rallyId}.json?`,
        params: {
            token: token,
            brand: brand,
            tramo_id: trackId,
        },
    })
}

export function getFiltersBrand(rallyId: number, token: string, brand: string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/routes_reports_filters/${rallyId}.json?`,
        params: {
            token: token,
            brand: brand,
            tramo_id: 0,
        },
    })
}

export function putFiltersBrand(rallyId: number, token: string, brand: string, trackId: number, data: any) {
    const put = [
        {
            tramo_id: trackId,
            brand: brand,
            data: JSON.stringify(data),
        },
    ]
    const formBody = 'json_data=' + encodeURIComponent(JSON.stringify(put))
    try {
        axios({
            method: 'PUT',
            url: `https://rest.anube.es/rallyrest/default/api/routes_reports_filters/${rallyId}.json`,
            params: {
                token: token,
            },
            data: formBody,
        })
            .catch(error => {
                console.error(error)
                return false
            })
            .then(() => {
                return true
            })
    } catch {
        return
    }
}

export function getOrganizerTrack(trackid: number, token: string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/section_path/${trackid}.json`,
        params: {
            token: token,
            also_hidden: 1,
        },
    })
}



export function getNeutralizationsForTrack(rallyId: number, trackId: number, token: string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/neutralizations/${rallyId}/${trackId}.json`,
        params: {
            token: token,
            type: 'waypoints2',
        },
    })
}

export function getSpeedZoneWaypointsForTrack(rallyId: number, trackId: number, token: string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/speed_zones/${rallyId}/${trackId}.json`,
        params: {
            token: token,
        },
    })
}
