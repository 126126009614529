import React, { forwardRef, useImperativeHandle, useState } from 'react';

interface Props {
    icon : any ,
    onActivateControl : () => void,
    onDeactivateControl : () => void
}
const ToggleActionButton = forwardRef(({ icon, onActivateControl, onDeactivateControl } : Props, ref :any) => {


    const [selected, setSelected] = useState<boolean>(false)

    const handlerClick = () => {
        if (selected) {
            onDeactivateControl();
        } else {
            onActivateControl();
        }
        setSelected(!selected)
    }

    let className = 'ol-control';
    const Icon = icon
    if (selected) {
        className += ' action-button-selected';
    }


    useImperativeHandle(ref, () => ({
        handlerClick,
    }));

    return (
        <div className={className}>
            <button
                type="button"
                onClick={handlerClick}
                ref={ref}
            >
                <Icon></Icon>

            </button>
        </div>
    )
})
export default ToggleActionButton;