import * as XLSX from 'xlsx'
import { timeUtcToHHMMSS, timespanToHHMMSS } from '../../utils'
import type { CustomZoneExcelRow, ExcelData } from '../../types/excel'

export const customZoneExcelData = (info: CustomZoneResult[], timezone: string) => {
    var customZoneExcel: CustomZoneExcelRow[] = []

    for (let index = 0; index < info.length; index++) {
        const participantResult =info[index]
        for (let j = 0; j < participantResult.data.laps.length; j++) {
            const lapResult = participantResult.data.laps[j]
            
            customZoneExcel.push({
                Participant : participantResult.number,
                Lap : j+1 ,
                "Entry time" : lapResult.entry.timeUtc === null ? '' : timeUtcToHHMMSS(lapResult.entry.timeUtc, timezone),
                "Exit time" : lapResult.exit.timeUtc === null ? '' : timeUtcToHHMMSS(lapResult.exit.timeUtc, timezone),
                "Total time" :lapResult.entry.timeUtc === null ||  lapResult.exit.timeUtc === null
                ? ''
                : timespanToHHMMSS(lapResult.exit.timeUtc -lapResult.entry.timeUtc),
                "Avg speed (km/h)" :    lapResult.participantAverageSpeed === null ? '' : lapResult.participantAverageSpeed,
                "Max speed (km/h)" :    lapResult.cardSpeedPoint === null ? '' :  lapResult.cardSpeedPoint[3].toString(),
                "Max speed limit (km/h)" : isNaN(lapResult.maxSpeed) ? '' : lapResult.maxSpeed.toString(),
                "Min speed (km/h)" : lapResult.cardSlowSpeedPoint === null
                ? ''
                : lapResult.cardSlowSpeedPoint[3].toString(),
                "Min speed limit (km/h)" : isNaN(lapResult.minSpeed) ? '' : lapResult.minSpeed.toString(),
                "Tolerance (km/h)" : lapResult.tolerance,
                "Speeding time (s)" :lapResult.speedInfo.fastestRange === null
                ? ''
                : lapResult.speedInfo.fastestRange.duration.toString() ,

            }
        )
        }
    }


    const customZoneWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(customZoneExcel)

    const excelData: ExcelData = {
        worksheet: customZoneWorksheet,
        sheetName: 'Custom Report',
    }

    return [excelData]
}
