import axios from 'axios'

import { getFirstIndexInArea, getLastIndexInArea, mergeArraysKeepingCommon } from '../../outtrack/common'
import { haversineDistance, innerAngle } from '../../utils'

export function processTracksByWaypoints(
    waypoints: Waypoint[],
    compareTracks: number[][][],
    serialsNumber: number[],
    gpsCheckedWaypoints: WaypointCheck[],
    firstWp: Waypoint,
    minTimeInZone: number
) {
    compareTracks
    const unmatchedWaypointToGpsIndexes = matchPathsByIntersectingWaypointsToGps(gpsCheckedWaypoints, waypoints)
    for (let index = 0; index < unmatchedWaypointToGpsIndexes.length; index++) {
        const element = unmatchedWaypointToGpsIndexes[index]
        waypoints[element.index].matchedGps = element.matched
        waypoints[element.index].blocked = element.blocked
        waypoints[element.index].timeMatched = element.timeMatched
        waypoints[element.index].serialNumber = element.sn
    }
    const waypointsPerValidateIndexes = waypoints
        .map((waypoint, index) => (!waypoint.matchedGps ? index : -1))
        .filter(index => index !== -1)

    const unmatchedWaypointIndexes: UnmatchedWaypointIndex[] = compareTracks.map((track, index) =>
        matchPathsByIntersectingWaypoints(
            waypoints,
            waypointsPerValidateIndexes,
            track,
            serialsNumber[index],
            firstWp,
            minTimeInZone
        )
    )
    const seeTimeMatched =
        unmatchedWaypointIndexes[0].waypointsTimeMatched.length >=
        unmatchedWaypointIndexes[1].waypointsTimeMatched.length
            ? unmatchedWaypointIndexes[0]
            : unmatchedWaypointIndexes[1]

    seeTimeMatched.waypointsTimeMatched.forEach((unmatchedWaypoint: Waypoint) => {
        const index = waypoints.findIndex(waypoint => waypoint.id === unmatchedWaypoint.id)
        if (index) {
            waypoints[index].timeMatched = unmatchedWaypoint.timeMatched
        }
    })

    let firstUnfinished = false
    const newWaypoints = waypoints.map(waypoint => {
        if (unmatchedWaypointIndexes[0].waypointFinded) {
            if (!firstUnfinished) {
                if (waypoint.id === unmatchedWaypointIndexes[0].waypointFinded.wpId) {
                    waypoint.firstUnfinished = true
                    firstUnfinished = true
                    waypoint.unfinished = true
                }
            } else {
                waypoint.unfinished = true
            }
        }
        return waypoint
    })

    const processedWaypoint: ProcessedWaypoint = {
        unmatchedIndexes: seeTimeMatched,
        waypoints: newWaypoints,
    }
    return processedWaypoint
}

export function matchPathsByIntersectingWaypointsToGps(gpsCheckedWaypoints: WaypointCheck[], waypoints: Waypoint[]) {
    const matchedIndex = []
    for (let index = 0; index < waypoints.length; index++) {
        const unmatchedGpsAndWaypoints = gpsCheckedWaypoints.find(element => element.wp_id === waypoints[index].id)

        if (unmatchedGpsAndWaypoints) {
            matchedIndex.push({
                index: index,
                blocked: unmatchedGpsAndWaypoints.blocked,
                timeMatched: unmatchedGpsAndWaypoints.ut,
                sn: unmatchedGpsAndWaypoints.sn,
                matched: unmatchedGpsAndWaypoints.ut > 0,
            })
        }
    }
    return matchedIndex
}

export function matchPathsByIntersectingWaypoints(
    waypoints: Waypoint[],
    indexes: number[],
    comparePath: number[][],
    serialNumber: number,
    firstWp: Waypoint,
    minTimeInZone: number
): UnmatchedWaypointIndex {
    if (comparePath.length === 0) {
        const unmatchedWaypointIndex: UnmatchedWaypointIndex = {
            waypointsTimeMatched: [],
            waypointFinded: undefined,
        }

        return unmatchedWaypointIndex
    }
    let waypointsZones: IntersectingWaypoint[] = []
    let firstIndex = getLastIndexInArea(
        firstWp.longitude,
        firstWp.latitude,
        firstWp.validation_radio,
        comparePath,
        0,
        firstWp.bearing
    )
    let participantMinTimeZone: number
    if (firstIndex !== null && minTimeInZone) {
        if (comparePath[firstIndex]) {
            participantMinTimeZone = comparePath[firstIndex][2] + minTimeInZone
        }
    }
    waypoints.map(waypoint => {
        let index = getFirstIndexInArea(
            waypoint.longitude,
            waypoint.latitude,
            waypoint.validation_radio,
            comparePath,
            0,
            null
        )
        if (index !== null) {
            if (comparePath[index]) {
                waypointsZones.push({
                    wpId: waypoint.id,
                    utcEntry: comparePath[index][2],
                })
            }
        }
    })
    let waypointsMatched: Waypoint[] = []

    for (let index = 0; index < indexes.length; index++) {
        for (let index2 = 0; index2 < comparePath.length; index2++) {
            if (
                haversineDistance(waypoints[indexes[index]].coordinate, [
                    comparePath[index2][0],
                    comparePath[index2][1],
                ]) < waypoints[indexes[index]].validation_radio
            ) {
                if (
                    waypoints[indexes[index]].bearing === null ||
                    (innerAngle(comparePath[index2][4], waypoints[indexes[index]].bearing!) < 90 &&
                        comparePath[index2][3] > 4)
                ) {
                    waypoints[indexes[index]].matched = true
                    waypointsMatched.push({
                        ...waypoints[indexes[index]],
                        timeMatched: comparePath[index2][2],
                        serialNumber: serialNumber,
                    })
                    break
                }
            }
        }
    }
    let waypointFinded

    if (minTimeInZone > 0) {
        waypointFinded = waypointsZones.find(e => {
            return e.utcEntry > participantMinTimeZone
        })
    }

    const unmatchedWaypointIndex: UnmatchedWaypointIndex = {
        waypointsTimeMatched: waypointsMatched,
        waypointFinded: waypointFinded,
    }

    return unmatchedWaypointIndex
}

export const finishState = {
    DNF: 'DNF',
    DNS: 'DNS',
    FINISH: 'FINISH',
}

export function getDSSWaypoint(waypoints: Waypoint[]) {
    const wpFirst = waypoints.find((wp: Waypoint) => wp.type === 'DSS')
    return wpFirst
}

export function getASSWaypoint(waypoints: Waypoint[]) {
    const wpFinal = waypoints.filter((wp: Waypoint) => wp.type === 'ASS').pop()
    // We can't use this because the ecmascript version
    // let wpFinal = waypoints.findLast((wp: WaypointAPI) => wp.type === 'ASS')
    return wpFinal
}

//check the "blocked" param from api and check if the participant start/end
export function checkBlocked(waypoints: Waypoint[]) {
    var result = finishState.FINISH
    const wpFirst: Waypoint | undefined = getDSSWaypoint(waypoints)
    const wpFinal: Waypoint | undefined = getASSWaypoint(waypoints)

    if (wpFirst && wpFinal) {
        if (wpFirst.blocked) {
            if (!wpFirst.matched && !wpFirst.matchedGps) {
                // If the participant didn't start and blocked is true 'DNS' do not start
                result = finishState.DNS
            } else if (!wpFinal.matched && !wpFinal.matchedGps) {
                // If the participant didn't end and blocked is true 'DNF' do not finish
                result = finishState.DNF
            }
        }
    }
    return result
}

export function putWaypointCheck(
    waypoint: Waypoint,
    subrallyId: number,
    token: string,
    participantId: number,
    ut: number,
    sn: number | null = null
) {
    return fetch('https://rest.anube.es/rallyrest/default/api/waypoint_checks/' + subrallyId + '.json?token=' + token, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'json_data=' + JSON.stringify([{ ut: ut, wp_id: waypoint.id, dorsal_id: participantId, sn: sn }]),
    })
}
