import React from 'react'
import { Button } from 'antd'
import { neutralizationExcel } from '../../../excelReport/neutralizationExcel'
interface Props {
    timezone: string
    info: NeutralizationResult[]
    text: string
    disabled: boolean
}
const NeuZoneDownloader = ({ info, text, timezone, disabled }: Props) => {
    return (
        <Button
            onClick={() => {
                neutralizationExcel(info, timezone)
            }}
            disabled={disabled}
        >
            {text}
        </Button>
    )
}

export default NeuZoneDownloader
