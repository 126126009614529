import { useEffect, useState, type Key } from 'react'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import TrackCard from '../../components/ParticipantCard/TrackCard/trackCard'
import '../../app.css'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import HeaderData from '../../components/dataHeader'
import { defaultSortings } from '../../constants/sortingConstants'
import { useResultsEffect } from '../../hooks/useResults'
import StatisticsCard from '../../components/ParticipantCard/StatisticsCard'

interface Props {
    results: StatisticsResult[]
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>> 
    timezone : string 
}
const StatisticsView = ({ results, setSpeedGraphic, setRallyMap , timezone }: Props) => {
    const [selectedResult, setSelectedResult] = useState<any | null>(null)
    const [filteredResults, setFilteredResults] = useState<any[]>(results)
    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)

    const sortings: SortingObject[] = [
        ...defaultSortings,
    ]

    const [sorting, setSorting] = useState<(resultA: Results, resultB: Results) => number>(() => sortings[0].sorting)

    const applyFilters = (sortedResults: any[]) => {
        setFilteredResults([...sortedResults])
    }

    useResultsEffect(results, sorting, [], applyFilters)

    return (
        <div className="flex h-full flex-col">
            <div className="m-2 ">
                <HeaderData
                    defaultSorting={sortings[0]}
                    filteredComponentByZone={null}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filterComponents={[]}
                ></HeaderData>
            </div>
            <div className="h-full flex-1 overflow-y-auto p-2">
                {filteredResults.map((result: StatisticsResult, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <StatisticsCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: StatisticsResult) => setSelectedResult(result)}
                                    timezone={timezone}
                                />
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default StatisticsView
