import * as api from '../../outtrack/rallyrestapi';

export default class NeutralizationHandler { 

    async getAnalysisSpecificInfo(rallyId : number, section : Section, token : string) {
        const sectionId = section.section;
        const waypointsResp = await api.getNeutralizationsForTrack(rallyId, sectionId, token);
        const data = waypointsResp.data.event.data;

        return data
    }
};
