import moment from 'moment'

export const formatAreaPointTimeExcelCell = (timeUTC: number, timezone: string): string => {
    if (timeUTC === -1 || timeUTC === 0 || !timeUTC) {
        return ''
    }

    const timeInMilliseconds = timeUTC * 1000

    // If the year is less than 2000 or bigger than 2100 we return ''
    if (moment(timeInMilliseconds).year() <= 2000 || moment(timeInMilliseconds).year() >= 2100) {
        return ''
    }

    return moment(timeInMilliseconds).tz(timezone).format('HH:mm:ss')
}


