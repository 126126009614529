import { useEffect } from 'react'

export function useResultsEffect(results: Results[], sorting: any, dependencies: any, applyFilters: (sortedResults :any) => void) {
    useEffect(() => {
        if (results) {
            let sortResults = sorting(results)
            applyFilters(sortResults)
        }
    }, [results, ...dependencies, sorting])
}
