import axios from 'axios'

interface WorkerWithPoolId extends Worker {
    workerPoolId?: number // Tipo de dato del workerPoolId, puede ser string, number, etc.
}
export class WorkerPool {
    maxWorkers: number
    activeWorkers: WorkerWithPoolId[]
    idleWorkers: WorkerWithPoolId[]
    freed: boolean

    constructor(maxWorkers: number, caseSwitch: string) {
        this.maxWorkers = maxWorkers
        this.activeWorkers = []
        this.idleWorkers = []
        this.freed = false
        for (let i = 0; i < this.maxWorkers; i++) {
            let worker: WorkerWithPoolId | undefined
            switch (caseSwitch) {
                case 'match':
                    worker = new Worker(new URL('./matchTrackAnalyzer/matchTrackAlgorithm', import.meta.url))

                    break
                case 'opposite':
                    worker = new Worker(new URL('./oppositeAnalyzer/oppositeAlgorithm', import.meta.url))

                    break
                case 'speed':
                    worker = new Worker(new URL('./speedZoneAnalyzer/speedZoneAlgorithm', import.meta.url))

                    break
                case 'custom':
                    worker = new Worker(new URL('./customZoneAnalyzer/customZoneAlgorithm', import.meta.url))

                    break
                case 'neutralization':
                    worker = new Worker(new URL('./neutralizationAnalyzer/neutralizationAlgorithm', import.meta.url))
                    break
                case 'waypoints':
                    worker = new Worker(new URL('./waypointsAnalyzer/waypointsAlgorithm', import.meta.url))
                    break
                case 'time':
                    worker = new Worker(new URL('./timeInZoneAnalyzer/timeInZoneAlgorithm', import.meta.url))
                    break
                case 'loop':
                    worker = new Worker(new URL('./loopAnalyzer/loopAlgorithm', import.meta.url))
                    break
                case 'statistics':
                    worker = new Worker(new URL('./statisticsAnalyzer/statisticsAlgorithm', import.meta.url))
                    break
                case 'generic-speed':
                    worker = new Worker(new URL('./genericSpeedAnalyzer/genericSpeedAlgorithm', import.meta.url))
                    break
            }
            if (worker) {
                worker.workerPoolId = i
                this.idleWorkers.push(worker)
            }
        }
    }

    async get() {
        while (!this.freed && !this.idleWorkers.length) {
            await new Promise(r => setTimeout(r, 0))
        }

        if (this.freed) {
            return null
        }

        const worker = this.idleWorkers.pop()
        if (worker) {
            this.activeWorkers.push(worker)
        }
        return worker
    }

    put(worker: WorkerWithPoolId) {
        if (!this.freed) {
            this.activeWorkers.splice(
                this.activeWorkers.findIndex(e => e.workerPoolId === worker.workerPoolId),
                1
            )
            this.idleWorkers.push(worker)
        }
    }

    free() {
        if (!this.freed) {
            this.freed = true
            this.activeWorkers.forEach(worker => worker.terminate())
            this.idleWorkers.forEach(worker => worker.terminate())
            this.activeWorkers = []
            this.idleWorkers = []
        }
    }
}
