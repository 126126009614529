/**
 * All diferents types of report
 */
export const reportTypes = [
    { value: 'matching', label: 'Match track' },
    { value: 'opposite', label: 'Opposite direction' },
    { value: 'waypoints', label: 'Waypoints' },
    { value: 'neut-zones', label: 'Neut. zones' },
    { value: 'genericSpeed', label: 'Generic Max Speed' },
    { value: 'speed', label: 'Speed zones' },
    { value: 'custom-zone', label: 'Custom zone' },
    { value: 'statistics', label: 'Statistics' },
    // { key: 'time-in-zone', name: 'Time in zone' },
    // { key: 'loops', name: 'Loop counter' },
]
