import type { Rule } from 'antd/es/form'

export const CoordinatesRules: Rule[] = [
    {
        pattern: new RegExp(/^-?(90|[0-8]?\d)(\.\d+)?, *-?(180|1[0-7]\d|\d?\d)(\.\d+)?$/gm),
        validator: (_, value) => {
            if (value.length === 2) {
                if (value[0] !== undefined && value[1] !== undefined && !isNaN(value[0]) && !isNaN(value[1]))
                    return Promise.resolve()
            }
            const coord = value.split(',')
            if (coord.length === 2) {
                if (coord[0] && coord[1] && !isNaN(Number(coord[0].trim())) && !isNaN(Number(coord[1].trim()))) {
                    return Promise.resolve()
                }
            }
            return Promise.reject('Invalid Coordinate')
        },
        message: 'Invalid Coordinate',
    },
]
