import * as XLSX from 'xlsx'
import type { ExcelData } from '../../types/excel'
import { formatAreaPointTimeExcelCell } from '../../utils/reports/waypointsUtils'

function generateWaypointExcelData(info: WaypointsResult[], timezone: string): any[] {
    const waypointExcel: any[] = []

    for (const result of info) {
        if (result.data.waypoints.length > 0) {
            const row: any = {
                Participant: result.number,
            }

            for (let areaPoint of result.areaPoints) {
                row[areaPoint.name] = formatAreaPointTimeExcelCell(areaPoint.timeMatched, timezone)
            }

            waypointExcel.push(row)
        }
    }

    return waypointExcel
}

export const waypointsTimeExcelData = (info: WaypointsResult[], timezone: string) => {
    const waypointExcel = generateWaypointExcelData(info, timezone)

    const waypointWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(waypointExcel)

    const excelData: ExcelData = {
        worksheet: waypointWorksheet,
        sheetName: 'Waypoints Report',
    }

    return [excelData]
}
