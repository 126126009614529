import { Button } from 'antd'
import { waypointExcel } from '../../../excelReport/waypointExcel'
interface Props {
    info: WaypointsResult[]
    text: string
    disabled: boolean
}
const WaypointsDownloader = ({ info, text, disabled }: Props) => {
    return (
        <Button
            onClick={() => {
                waypointExcel(info)
            }}
            disabled={disabled}
        >
            {text}
        </Button>
    )
}

export default WaypointsDownloader
