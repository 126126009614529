import React, { Component, useContext, useEffect, useState } from 'react'
import 'moment-timezone'
import moment from 'moment'
import SpeedChart from './SpeedChart/speedChart'
import { SpeedGraphicContext } from '../../contexts/speedGraphicContext'

interface Props {
    visible: boolean
    colors: string[]
    timezone: string
    onPoint: (point: number[] | undefined) => void
}
const SpeedView = ({ colors, onPoint, timezone, visible }: Props) => {
    const speedGraphic = useContext(SpeedGraphicContext)
    const [timeUi, setTimeUi] = useState('--')
    const [speedUi, setSpeedUi] = useState('--')
    const [invisible, setInvisible] = useState('invisible')

    const getTimeUi = (point: number[] | undefined) => {
        if (point) {
            setTimeUi(
                moment(point[2] * 1000)
                    .tz(timezone)
                    .format('HH:mm:ss')
            )
            setSpeedUi(point[3] + ' km/h')
            setInvisible('')
            onPoint(point)
        }
    }

    useEffect(() => {
        setInvisible('invisible')
    }, [speedGraphic.tracks])
    return (
        <React.Fragment>
            <div
                className={
                    'speed-info border-0 border-t border-solid border-neutral-300 bg-neutral-100 ' +
                    invisible +
                    ' ' +
                    (visible ? '' : 'hidden')
                }
            >
                <b>Time:</b> {timeUi} | <b>Speed:</b> {speedUi}
            </div>
            <div
                className={`chart ${visible ? '' : 'hidden'}  z-50 m-2 rounded border border-solid border-neutral-300 bg-neutral-100`}
            >
                <SpeedChart
                    tracks={speedGraphic.tracks}
                    speedRanges={speedGraphic.speedRanges}
                    colors={colors}
                    timezone={timezone}
                    onPoint={getTimeUi}
                />
            </div>
        </React.Fragment>
    )
}
export default SpeedView
