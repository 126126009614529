import React, { useEffect, useState } from 'react'

interface Props {
    number: string
    children: JSX.Element
}
const ErrorBoundarySummaryCard = ({ number, children }: Props) => {
    const [hasError, setHasError] = useState<boolean>(false)
  
    return hasError ? (
        <div className="summary-card">
            <div className="card-number">{number}</div>
            <div className="card-data">something went wrong</div>
        </div>
    ) : (
        <>{children}</>
    )
}
export default ErrorBoundarySummaryCard
