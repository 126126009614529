import * as XLSX from 'xlsx'
import { timespanToHHMMSS, timeUtcToHHMMSS } from '../../utils'
import type { ExcelData, SpeedExcelRow } from '../../types/excel'

export const speedZoneExcelData = (info: SpeedZoneResult[], timezone: string) => {
    var speedExcel: SpeedExcelRow[] = []

    info.forEach((participantResult: SpeedZoneResult) => {
        const speedZones = participantResult.data.zones
        speedZones.forEach((speedZonesDatas: SpeedZoneData[]) => {
            speedZonesDatas.forEach((speedZoneData, index) => {
                const zoneResult = speedZoneData.analysisInfo
                if (zoneResult.reached && !zoneResult.overlapping) {
                    const { entryInfo, exitInfo, cardSpeedPoint, cardExcess, fastestRange } = zoneResult
                    const getTimeFromPoint = (point: any) => (point ? timeUtcToHHMMSS(point[2], timezone) : '')
                    const getTotalTime = (): string | undefined => {
                        if (entryInfo && exitInfo) {
                            return timespanToHHMMSS(exitInfo.point[2] - entryInfo.point[2])
                        }
                    }
                    const getInfringementTime = (): string | undefined => {
                        if (cardSpeedPoint && cardSpeedPoint[2]) {
                            return timeUtcToHHMMSS(cardSpeedPoint[2], timezone)
                        }
                    }

                    speedExcel.push({
                        'Number Run': index + 1,
                        Participant: participantResult.number,
                        'Zone name': speedZoneData.serverInfo.zoneName,
                        'Speed Limit': speedZoneData.serverInfo.maxSpeed,
                        'Entry time': getTimeFromPoint(entryInfo?.point),
                        'Exit time': getTimeFromPoint(exitInfo?.point),
                        'Total time': getTotalTime(),
                        'Infringement time': getInfringementTime(),
                        'Max speed (km/h)': cardSpeedPoint ? cardSpeedPoint[3] : undefined,
                        'Infringement (km/h)': cardExcess || undefined,
                        'Speeding time (s)': fastestRange ? fastestRange.duration : undefined,
                    })
                } else {
                    speedExcel.push({
                        'Number Run': index + 1,
                        Participant: participantResult.number,
                        'Zone name': speedZoneData.serverInfo.zoneName,
                        'Speed Limit': speedZoneData.serverInfo.maxSpeed,
                    })
                }
            })
        })
    })

    const speedWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(speedExcel)

    const excelData: ExcelData = {
        worksheet: speedWorksheet,
        sheetName: 'Speed Report',
    }

    return [excelData]
}
