import React from 'react';
import './trackinfo.css';

interface Props {
    className : string ,
    track :  Section | null ,
    rallyId : number 
}
const TrackInfo = ({className, track , rallyId} : Props) => {
    
    return (
        <div
            className={className + ' track-info'}
        >
            {track !== null &&
                <React.Fragment>
                    <b>{track.name} (ID {track.section})</b>
                    <span> - </span>
                    <a
                        href={track.adminLink}
                        target='_blank'
                    >
                        Admin
                    </a>
                </React.Fragment>
            }
        </div>
    );
}

export default TrackInfo;
