import { Card, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { BaseFormContext } from '../../contexts/baseFormContext'
import { SpecificFormContext } from '../../contexts/specificFormContext'

interface Props {
    children: JSX.Element
}

/**
 * Motor for the specific form
 */
const SpecificForm = forwardRef(({ children }: Props, ref) => {
    const [specificForm] = useForm()
    const setSpecificData = useContext(BaseFormContext)
    useImperativeHandle(ref, () => ({
        validate: async () => {
            try {
                await specificForm.validateFields()
                return true
            } catch (error) {
                return false
            }
        },
        changeValues: (filters: any) => {
            specificForm.setFieldsValue(filters)
            setSpecificData(specificForm.getFieldsValue())
        },
    }))

    useEffect(() => {
        if (specificForm) setSpecificData(specificForm.getFieldsValue())
    }, [specificForm, children])

    const handleFormValuesChange = (changedValues: any, allValues: any) => {
        setSpecificData(allValues)
    }
    return (
        <Card bodyStyle={{ padding: 2 }} className="mt-1 w-full border-solid border-neutral-300  bg-neutral-100 p-2">
            <Form.Item className=" w-full">
                <SpecificFormContext.Provider value={{ form: specificForm }}>
                    <Form
                        size="small"
                        form={specificForm}
                        onValuesChange={handleFormValuesChange}
                        className="w-full space-y-1"
                    >
                        {children}
                    </Form>
                </SpecificFormContext.Provider>
            </Form.Item>
        </Card>
    )
})

export default SpecificForm
