import React from 'react'
import { Tooltip } from 'react-tooltip'

interface Props {
    disabled: boolean
    values: string[]
    value: string
    onChange: (value: string) => void
}
const RangeList = ({ disabled, onChange, value, values }: Props) => {
    return (
        <>
            <input
                list="items-datalist"
                disabled={disabled}
                type="text"
                className="select w-full"
                value={value}
                placeholder="<all>"
                onChange={e => onChange(e.target.value)}
            />

            <datalist id="items-datalist">: {values?.map((e: string) => <option key={e}>{e}</option>)}</datalist>
        </>
    )
}

export default RangeList
