import { calculateLostPositions, timeUtcToHHMMSS } from '../../../utils'
import PositionsLost from '../../PositionsLost'
import ParticipantCard from '../participantCard'
import 'moment-timezone'

interface Props {
    className: string
    onClick: (result: StatisticsResult) => void
    result: StatisticsResult
    timezone: string
}

const StatisticsCard = ({ className, onClick, result, timezone }: Props) => {
    let mainPositions = calculateLostPositions(result.disconnectedGpsTrack.main)
    let secondaryPositions = calculateLostPositions(result.disconnectedGpsTrack.secondary)
    const resetsMainSerialNumber = result.data.statisticsResult.devicesResets.filter(
        e => e.sn === result.data.statisticsResult.mainSerialNumber
    )
    let resetsSecondarySerialNumber: StellaApi[] = []
    if (result.data.statisticsResult.mainSerialNumber !== result.data.statisticsResult.secondarySerialNumber) {
        resetsSecondarySerialNumber = result.data.statisticsResult.devicesResets.filter(
            e => e.sn === result.data.statisticsResult.secondarySerialNumber
        )
    }
    const statistics = (
        <div className="float-right mr-2 flex-1 text-right flex flex-col ">
                <div className="float-right flex flex-row p-1 self-end">
                    SERIAL NUMBER:
                    <div className="font-bold pr-2 pl-2 min-w-10">{result.data.statisticsResult.mainSerialNumber}</div>
                    Resets
                    <div className="font-bold pl-2 min-w-4">{resetsMainSerialNumber.length}</div>
                </div>
                {resetsSecondarySerialNumber.length > 0 && (
                     <div className="float-right flex flex-row p-1 self-end">
                     SERIAL NUMBER:
                     <div className="font-bold pr-2 pl-2 min-w-10">{result.data.statisticsResult.secondarySerialNumber}</div>
                     Resets
                     <div className="font-bold pl-2 min-w-4">{resetsSecondarySerialNumber.length}</div>
                 </div>
                )}
        </div>
    )
    return (
        <ParticipantCard
            type="Statistics"
            number={result.number}
            onClick={() => onClick(result)}
            rallyId={result.rally}
            className={`${className}`}
        >
            {statistics}
            <PositionsLost positions={mainPositions + secondaryPositions}></PositionsLost>
        </ParticipantCard>
    )
}

export default StatisticsCard
