import { Select } from 'antd'
import { useEffect, useState } from 'react'

interface Props {
    criterias: CriteriaAPI[]
    criteriaUnique: CriteriaAPI | null
    [x: string]: any
}

const SelectCriterias = ({ criterias,criteriaUnique, ...restProps }: Props) => {
    const FIAFinded = criterias.find((e: CriteriaAPI) => e.description === 'FIA')
    const [criteria, setCriteria] = useState<CriteriaAPI>(FIAFinded ? FIAFinded : criterias[0])

    const handleCriteriaChange = (selectedOption: any) => {
        const criteriaFinded = criterias.find((e: any) => e.short_name === selectedOption)
        if (criteriaFinded) setCriteria(criteriaFinded)
    }
    useEffect(() => {
        restProps.onChange(criteria)
    }, [criteria])

    useEffect(()=>{
        if(criteriaUnique){
            const criteriaFinded = criterias.find((e: any) => e.short_name === criteriaUnique.short_name)
            if (criteriaFinded) setCriteria(criteriaFinded)
        }
    },[criteriaUnique])
    return (
        <Select
            value={criteria.short_name}
            onChange={handleCriteriaChange}
            options={criterias.map((c: CriteriaAPI) => ({ label: c.description, value: c.short_name }))}
        ></Select>
    )
}

export default SelectCriterias