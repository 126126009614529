import * as turf from '@turf/turf'
import { haversineDistance, objectContains } from '../../utils'
import { getClosestEntryIndex, getClosestExitIndex } from '../customZoneAnalyzer/customZoneFunctions'
import { getLastIndexInArea } from '../../outtrack/common'
import axios from 'axios'
import { putFiltersBrand } from '../../outtrack/rallyrestapi'

export const isSpeedInfringement = (distance: number, radius: number, maxSpeedLimit: number, currentSpeed: number) => {
    {
        return distance < radius && maxSpeedLimit < currentSpeed
    }
}

export const getIndexesByZones = (entryArea: Area, exitArea: Area, participantTrack: number[][]) => {
    let currentIndex = 0
    const slice = []
    while (true) {
        let exitIndex = getClosestEntryIndex(exitArea, participantTrack, currentIndex)
        let entryIndex = getClosestExitIndex(entryArea, participantTrack, currentIndex)
        if (entryIndex === null && exitIndex === null) {
            return slice
        }
        if (entryIndex !== null && exitIndex !== null) {
            if (entryIndex > exitIndex) {
                entryIndex = null
            } else {
                let indexAfterEntryArea =
                    Number(
                        getLastIndexInArea(
                            entryArea.longitude,
                            entryArea.latitude,
                            entryArea.radius,
                            participantTrack,
                            entryIndex,
                            null
                        )
                    ) + 1
                let nextEntryIndex = getClosestEntryIndex(entryArea, participantTrack, indexAfterEntryArea)
                if (nextEntryIndex !== null && nextEntryIndex < exitIndex) {
                    exitIndex = null
                }
            }
        }
        if (exitIndex && entryIndex) {
            for (let index = entryIndex; index < exitIndex; index++) {
                slice.push(index)
            }
        }
        const lastLapAreaIndex = Math.max(entryIndex, exitIndex)
        const lastLapArea = lastLapAreaIndex === entryIndex ? entryArea : exitArea
        currentIndex =
            Number(
                getLastIndexInArea(
                    lastLapArea.longitude,
                    lastLapArea.latitude,
                    lastLapArea.radius,
                    participantTrack,
                    lastLapAreaIndex,
                    null
                )
            ) + 1
    }
}

export const getIndexesByTrack = (participantTrack: number[][], track: number[][], threshold: number) => {
    let currentIndex = 0
    let validPoints: any[] = []
    for (let index = 0; index < track.length; index++) {
        for (let index2 = currentIndex; index2 < participantTrack.length; index2++) {
            const participant = participantTrack[index2]
            const distance = haversineDistance(track[index], [participant[0], participant[1]])
            if (distance < threshold && !validPoints.includes(index2)) {
                validPoints.push(index2)
            }
        }
    }

    return validPoints
}

export const infringementsByImpulseinMeters = (
    infringements: number[][],
    chunkedLine: turf.helpers.FeatureCollection<turf.helpers.LineString>,
    participantTrack: number[][],
    validIndexes: number[],
    threshold: number,
    speedLimit: number,
    minSeconds: number
) => {
    let currentIndex = 0
    let validPoints = []
    let maxSpeed = Number.MIN_SAFE_INTEGER
    if (participantTrack.length < 1) return maxSpeed

    for (let i = 0; i < chunkedLine.features.length; i++) {
        const feature = chunkedLine.features[i]
        let array2: number[] = []
        for (let j = 0; j < feature.geometry.coordinates.length - 1; j++) {
            for (let k = currentIndex; k < validIndexes.length; k++) {
                if (k === participantTrack.length - 1) validPoints.push(array2)
                array2.push(validIndexes[k])
                currentIndex = k + 1
            }
        }
        validPoints.push(array2)
    }

    const participantByminSecondss = groupByMinSeconds(participantTrack, speedLimit, minSeconds)
    maxSpeed = groupInfringements(validPoints, participantByminSecondss, participantTrack, maxSpeed, infringements)
    return maxSpeed
}
export const getIndexesByPointsInTrackMeters = (participantTrack: number[][], chunkedLine: any, threshold: number) => {
    let currentIndex = 0
    let validPoints = []
    if (participantTrack.length < 1) return []

    for (let i = 0; i < chunkedLine.features.length; i++) {
        const feature = chunkedLine.features[i]
        let array2: number[] = []
        for (let j = 0; j < feature.geometry.coordinates.length - 1; j++) {
            for (let k = currentIndex; k < participantTrack.length; k++) {
                const coords = feature.geometry.coordinates[j]
                const participant = participantTrack[k]
                const distance = haversineDistance(coords, [participant[0], participant[1]])
                if (distance < threshold) {
                    if (k === participantTrack.length - 1) validPoints.push(array2)
                    array2.push(k)
                    currentIndex = k + 1
                }
            }
        }
        validPoints.push(array2)
    }

    return validPoints
}

export const getIndexesByPointsInTrackSeconds = (
    track: number[][],
    participantTrack: number[][],
    threshold: number,
    impulsesValue: number
) => {
    let array2 = []
    let validPoints = []
    let currentIndex = 0
    // Agrupar por impulsos
    for (let index2 = currentIndex; index2 < participantTrack.length; index2++) {
        if (index2 % impulsesValue === 0) {
            validPoints.push(array2)
            array2 = []
        }
        if (index2 === participantTrack.length - 1) validPoints.push(array2)
        array2.push(index2)
        currentIndex = index2 + 1
    }
    return validPoints
}

export const infringementsByImpulseInSeconds = (
    infringements: number[][],
    participantTrack: number[][],
    validIndexes: number[],
    speedLimit: number,
    impulsesValue: number,
    minSeconds: number
) => {
    let maxSpeed = Number.MIN_SAFE_INTEGER
    if (participantTrack.length < 1) return maxSpeed
    let array2 = []
    let validPoints = []
    let currentIndex = 0
    // Agrupar por impulsos
    for (let index2 = currentIndex; index2 < validIndexes.length; index2++) {
        if (index2 % impulsesValue === 0) {
            validPoints.push(array2)
            array2 = []
        }
        if (index2 === participantTrack.length - 1) validPoints.push(array2)
        array2.push(validIndexes[index2])
        currentIndex = index2 + 1
    }
    const participantByminSeconds = groupByMinSeconds(participantTrack, speedLimit, minSeconds)
    maxSpeed = groupInfringements(validPoints, participantByminSeconds, participantTrack, maxSpeed, infringements)

    return maxSpeed
}
export function indexOfMaxSpeed(arr: any) {
    if (arr.length === 0) {
        return -1
    }

    var max = arr[0].participant[3]
    var object = arr[0]

    for (var i = 1; i < arr.length; i++) {
        if (arr[i].participant[3] > max) {
            object = arr[i]
            max = arr[i].participant[3]
        }
    }

    return object
}

const groupByMinSeconds = (participantTrack: number[][], speedLimit: number, minSeconds: number) => {
    let array = []
    let cont: number = 0
    let firstTimeInfringement: number = 0

    for (let index2 = 0; index2 < participantTrack.length; index2++) {
        const participant = participantTrack[index2]
        if (speedLimit < participant[3]) {
            if (cont === 0) firstTimeInfringement = index2
            cont++
        } else {
            if (cont >= minSeconds) {
                array.push([firstTimeInfringement, index2 - 1])
            }
            cont = 0
        }
    }

    return array
}

const groupInfringements = (
    validPoints: any[],
    participantByMinSeconds: number[][],
    participantTrack: number[][],
    maxSpeed: number,
    infringements: number[][]
) => {
    let c = []
    let realMaxSpeed: any = []
    if (validPoints.length < 1) return Math.max(...realMaxSpeed)
    if (participantByMinSeconds.length > 0) {
        for (let index = 0; index < participantByMinSeconds.length; index++) {
            let participantIndex = 0
            for (
                let index2 = participantByMinSeconds[index][0];
                index2 <= participantByMinSeconds[index][1];
                index2++
            ) {
                const participant = participantTrack[index2]
                if (participant[3] > maxSpeed) {
                    maxSpeed = participant[3]
                    participantIndex = index2
                }
            }
            const a = validPoints.findIndex((e: any[]) => {
                return e.find(e2 => e2 === participantIndex)
            })
            if (a !== -1) {
                c.push({ index: a, participantIndex: participantIndex })
            }
            maxSpeed = Number.MIN_SAFE_INTEGER
        }
        let maxValues: any = {}
        let maxValuesId: any = {}
        //Quitar repetidos en el mismo impulso
        for (let i = 0; i < c.length; i++) {
            let item = c[i]
            if (!maxValues[item.index] || maxValues[item.index] < participantTrack[item.participantIndex][3]) {
                maxValues[item.index] = participantTrack[item.participantIndex][3]
                maxValuesId[item.index] = item.participantIndex
            }
        }
        Object.values(maxValuesId).forEach(e => {
            const participant = participantTrack[Number(e)]
            infringements.push([...participant, Number(e)])
        })
    }
    for (let index = 0; index < validPoints.length; index++) {
        if (validPoints[index].length > 0) {
            for (
                let index2 = validPoints[index][0];
                index2 <= validPoints[index][validPoints[index].length - 1];
                index2++
            ) {
                realMaxSpeed.push(participantTrack[index2][3])
            }
        }
    }
    return findMax(realMaxSpeed)
}
export const findMax = (array: any) => {
    let max = -Infinity
    for (const num of array) {
        if (num > max) {
            max = num
        }
    }
    return max
}

export const getDevicesStatistics  = async ( token : string , subrallyId : number ) =>{
    return (await axios.get(`https://rest.anube.es/rallyrest/default/api/devices/${subrallyId}.json?token=${token}`)).data.event.data.devices
}
export const handlerFiltersPutGenericSpeed = async (
    rallyId: number,
    sectionId: number,
    token: string,
    detectionType: string,
    specificParams: any,
    brandFilters: any
) => {
    if (brandFilters.length > 0) {
        const jsonBrandFilters = JSON.parse(brandFilters[0].data)

        if(jsonBrandFilters.mode !== specificParams.mode) return  { jsonBrandFilters, specificParams }
        if (!objectContains(specificParams, jsonBrandFilters)) return { jsonBrandFilters, specificParams }
    } else {
        await putFiltersBrand(rallyId, token, detectionType, sectionId, specificParams)
    }
}

