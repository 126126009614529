import { Form, InputNumber, Tooltip } from 'antd'

const initialValueTolerance = 0
const initialValuePenalty = 0

/**
 * Different components of the form that need the neutralization zone to work
 * @returns Different form items
 */
const BaseSpecificNeutralizationForm = () => {
    return (
        <>
            <Form.Item
                name="penaltyTolerance"
                className="m-0 w-full"
                label={
                    <>
                        <Tooltip title="Tolerance in seconds allowed in order not to be penalized by the time limit">
                            {' '}
                            Tolerance
                        </Tooltip>
                    </>
                }
                initialValue={initialValueTolerance}
            >
                <InputNumber className="w-full" min={0} defaultValue={initialValueTolerance} precision={0} />
            </Form.Item>
            <Form.Item name="penaltyFactor" className="m-0 w-full" label="Penalty" initialValue={initialValuePenalty}>
                <InputNumber className="w-full" min={0} defaultValue={initialValuePenalty} precision={0} />
            </Form.Item>
        </>
    )
}
export default BaseSpecificNeutralizationForm
