import { handlerFiltersPutBrand } from '../../utils'
import Analyzer from '../analyzer'
import WaypointsHandler from './waypointsHandler'

export default class WaypointsAnalyzer extends Analyzer {
    waypointsResults: WaypointsResult[]
    resultHandler: WaypointsHandler

    constructor(
        rallyId: number,
        subRallyId: number,
        section: Section,
        excluded: any,
        connections: DevicesConnections,
        routesReports: any
    ) {
        super(rallyId, subRallyId, section, excluded, connections, routesReports)
        this.resultHandler = new WaypointsHandler(this, section, excluded)
        this.waypointsResults = []
        this.onResults = () => {}
    }
    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'waypoints')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches,
        brandFilters: any,
        modeSandBox: boolean
    ) => {
        const specificParams = this._getRequestParamsSpecific(data)
        let brands = null
        if (!modeSandBox) {
            brands = await handlerFiltersPutBrand(
                this.subrallyId,
                this.section.section,
                token,
                data.detectionType,
                specificParams,
                brandFilters
            )
        }

        if (brands) return brands

        let analysisSpecificInfo: Waypoint[]

        try {
            analysisSpecificInfo = await this.resultHandler.getAnalysisSpecificInfo(
                this.subrallyId,
                this.section,
                token
            )
        } catch (e) {
            console.log('getAnalysisSpecificInfo exception:', e)
            this.onResultError()
            return
        }

        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params: ParamsAnalyzerWaypoints = this._getRequestParams(
                this.rallyId,
                this.subrallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections,
                analysisSpecificInfo
            )

            const worker = await this._pool.get()
            if (worker == null) {
                break
            }

            worker.onmessage = async (e: MessageEvent<MessageAlgorithmWaypoints>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'waypointResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: WaypointsReport = e.data.result
                        this.resultHandler.excludeOutliers(resultInfo, this.excluded)

                        const areaPoints: AreaPointsWaypoints[] = this.resultHandler.getAreaPoints(resultInfo)

                        const disconnectedGpsTrack = generalParamsForReport?.disconnectedGpsTrack
                        const result: WaypointsResult = {
                            highlightedPoints: [{}, {}],
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            token: token,
                            rally: this.subrallyId,
                            participantTracks: generalParamsForReport?.participantTracks || [],
                            connections: generalParamsForReport?.connections,
                            areaPoints: areaPoints,
                            customTracks: [],
                            serialNumber: generalParamsForReport?.serialNumber,
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.waypointsResults = [...this.waypointsResults, result]
                        this.onResults(this.waypointsResults)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }

            worker.postMessage(['waypointProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }
        this._pool.free()

        console.timeEnd('analysis')
    }

    getResults = () => this.waypointsResults

    _getRequestParamsSpecific = (data: DataRallyForm) => {
        return {
            firstWp: data.detectionTypeData.firstWp,
            lastWp: data.detectionTypeData.lastWp,
            minTimeInZone: data.detectionTypeData.minTimeInZone,
        }
    }

    _getRequestParams = (
        rallyId: number,
        subrallyId: number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: DevicesConnections,
        analysisSpecificInfo: Waypoint[]
    ): ParamsAnalyzerWaypoints => {
        const reqData: ParamsAnalyzerWaypoints = {
            token: token,
            subrallyId: subrallyId,
            rallyId: rallyId,
            section: section,
            from: data.from,
            to: data.to,
            threshold: -1,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            participant: participant,
            firstWp: data.detectionTypeData.firstWp,
            lastWp: data.detectionTypeData.lastWp,
            minTimeInZone: data.detectionTypeData.minTimeInZone,
            analysisSpecificInfo: analysisSpecificInfo,
        }

        return reqData
    }
}
