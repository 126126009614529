import { saveExcel } from "./tools/saveExcel";
import * as XLSX from 'xlsx'
import { excelDataToWorkbook } from "./tools/excelDataToWorkbook";
import type { ExcelData } from "../types/excel";
import { waypointExcelData } from "./excelData/waypointExcelData";


export const waypointExcel = (info: WaypointsResult[]) => {
    const title: string = "waypoints-report"
    const data: ExcelData[] = waypointExcelData(info);
    const workbook: XLSX.WorkBook = excelDataToWorkbook(title, data)

    saveExcel(title, workbook);
}