import * as XLSX from 'xlsx'
import type { ExcelData } from '../../types/excel';

export const excelDataToWorkbook = (title: string, excelData: ExcelData[]) => {

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    workbook.Props = {
        Title: title,
        Author: "Anubesport",
    };

    excelData.forEach((excelData: ExcelData) => {
        XLSX.utils.book_append_sheet(workbook, excelData.worksheet, excelData.sheetName);
    })

    return workbook;
};