import { saveExcel } from "./tools/saveExcel";
import * as XLSX from 'xlsx'
import { excelDataToWorkbook } from "./tools/excelDataToWorkbook";
import type { ExcelData } from "../types/excel";
import { waypointExcelData } from "./excelData/waypointExcelData";
import { neutralizationExcelData } from "./excelData/neutralizationExcelData";


export const neutralizationExcel = (info: NeutralizationResult[],timezone : string) => {
    const title: string = "neutralization-report"
    const data: ExcelData[] = neutralizationExcelData(info,timezone);
    const workbook: XLSX.WorkBook = excelDataToWorkbook(title, data)

    saveExcel(title, workbook);
}