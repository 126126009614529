import React, { useEffect, useState } from "react";
import { Button, Table, Tag } from 'antd';
import './tableLegendType.css'
import { isArray } from "lodash";
import { secondsToPrettyformat } from "../../utils";

interface Props {
    data: AreaPoints[],
    type: string,
    setCoordinates: (entryCoords: number[], exitCoords: number[]) => void,
    clickCounter: number
}

interface CoordType {
    type?: string;
    coords: [number, number];
}

interface DataTableType {
    key: number;
    name: string[];
    coords: CoordType[] | number[][];
    number: number[] | number;
    type?: string;
    validation_radio?: number | any[];
    speedLimit?: number;
    speedIneu?: number;
    secondsIneu?: string;
    bearing?: number
}
interface Columns {
    title?: string,
    dataIndex: string,
    render: (a: any) => void
}
const TableLegendType = ({ data, type, setCoordinates, clickCounter }: Props) => {
    const cases = {
        0: () => setTableData([]),
        1: () => {
            filteredTable()
        },
        2: () => notFilteredTable()
    }
    const [tableData, setTableData] = useState<any[]>([]);

    const columnType: Columns =
    {
        title: 'Type',
        dataIndex: 'type',
        render: (a) => (
            <>
                {isArray(a) ? (
                    <>
                        {a[0] && (
                            <>

                                {a[0] + "  "}
                            </>
                        )}
                        {a[1] ? (
                            <>
                                {a[1]}
                            </>
                        ) : (
                            <> </>
                        )}
                    </>
                ) : (
                    <> {a}</>
                )}
            </>
        )
    }
    const columnValRadio: Columns =
    {
        title: 'Val Radio',
        dataIndex: 'validation_radio',
        render: (a) => (
            <>
                {isArray(a) ? (
                    <>
                        {a[0] && (
                            <>

                                {a[0] + "  "}
                            </>
                        )}
                        {a[1] && a[1] !== '' ? (
                            <>
                                {a[1]}
                            </>
                        ) : (
                            <> </>
                        )}
                    </>
                ) : (
                    <>{a}</>
                )}
            </>
        )
    }
    const columnBearing: Columns =
    {
        title: 'Bearing',
        dataIndex: 'bearing',
        render: (a) => (
            <>
                {isArray(a) ? (
                    <>
                        {a[0] && (
                            <>

                                {a[0] + "  "}
                            </>
                        )}
                        {a[1] && a[1] !== '' ? (
                            <>
                                {a[1]}
                            </>
                        ) : (
                            <> </>
                        )}
                    </>
                ) : (
                    <>{a}</>
                )}
            </>
        )
    }
    const columnSpeedLimit: Columns =
    {
        title: 'Speed Limit',
        dataIndex: 'speedLimit',
        render: (a) => (

            <>
                {a}
            </>
        )
    }
    const columnSpeedIneu: Columns =
    {
        title: 'Speed INEU',
        dataIndex: 'speedIneu',
        render: (a) => (

            <>
                {a}
            </>
        )
    }
    const columnSecondsIneu: Columns =
    {
        title: 'Seconds Ineu',
        dataIndex: 'secondsIneu',
        render: (a) => (

            <>
                {a}
            </>
        )
    }
    const initialColumns: Columns[] = [
        {
            title: 'Number',
            dataIndex: 'number',
            render: (a) => (
                <>
                    {isArray(a) ? (
                        <>
                            {a[0] && (
                                <>

                                    {a[0] + "  "}
                                </>
                            )}
                        </>
                    ) : (
                        <> {a}</>
                    )}
                </>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (a) =>
            (
                <>
                    {(a as string[]).length > 1 ? (
                        <>
                            {a[0] && (
                                <>

                                    {a[0] + "  "}
                                </>
                            )}
                            {a[1] && a[1] !== '' ? (
                                <>
                                    {a[1]}
                                </>
                            ) : (
                                "_END_"
                            )}
                        </>
                    ) : (
                        <>{a[0]}</>
                    )}
                </>
            )

        },


    ]
    const columnsPasteCoords: Columns = {
        dataIndex: 'coords',
        render: (a) =>
        (
            <>
                {a.length > 1 ? (
                    <Button size="small" className='pasteCoords' onClick={() => setCoordinates(a[0], a[1])}>
                        Paste Coords into Form
                    </Button>
                ) : (
                    <Button size="small" className='pasteCoords' onClick={() => navigator.clipboard.writeText(a[0].coords)}>
                        Copy Coords
                    </Button>
                )}
            </>
        )

    }
    const [columns, setColumns] = useState<any[]>(initialColumns);

    useEffect(() => {
        setColumns(initialColumns)
        switch (type) {
            case 'waypoints':
                if (clickCounter % 3 === 2) {
                    setColumns((prevColumns) => [...prevColumns, columnType, columnValRadio, columnBearing])
                }
                setColumns((prevColumns) => [...prevColumns, columnsPasteCoords])
                break;
            case 'speed_zones':
                setColumns((prevColumns) => [...prevColumns, columnSpeedLimit, columnsPasteCoords])
                break;
            case 'neutralizations':
                setColumns((prevColumns) => [...prevColumns, columnSpeedIneu, columnSecondsIneu, columnsPasteCoords])
                break;
            default:
                break;
        }
        cases[clickCounter % (type === 'waypoints' ? 3 : 2) as 0 | 1 | 2]()

    }, [data, clickCounter]);

    const notFilteredTable = () => {
        let dataTable: DataTableType[] = [];
        for (let i = 0; i < data.length; i++) {

            dataTable.push({
                key: i / 2,
                name: [data[i]?.name],
                coords: [{ type: data[i]?.type, coords: [data[i]?.latitude!, data[i]?.longitude!] }],
                number: data[i]?.number!,
                type: data[i]?.type,
                validation_radio: data[i]?.validation_radio,
                bearing: data[i]?.bearing
            });

        }
        setTableData(dataTable);
    }
    const filteredTable = () => {

        let dataTable: DataTableType[] = [];
        for (let i = 0; i < data.length; i += 2) {
            if (i + 1 < data.length && data[i].type !== data[i + 1].type) {
                dataTable.push({
                    key: i / 2,
                    name: [data[i]?.name, data[i + 1]?.name],
                    coords: [[data[i]?.latitude!, data[i]?.longitude!], [data[i + 1]?.latitude!, data[i + 1]?.longitude!]],
                    number: [data[i]?.number!, data[i + 1]?.number!],
                    validation_radio: [data[i]?.validation_radio, data[i + 1]?.validation_radio],
                    speedLimit: data[i].speed,
                    speedIneu: data[i].speedLimit,
                    secondsIneu: secondsToPrettyformat(data[i].seconds)
                });
            } else {
                dataTable.push({
                    key: i / 2,
                    name: [data[i]?.name],
                    coords: [{ type: data[i]?.type, coords: [data[i]?.latitude!, data[i]?.longitude!] }],
                    number: data[i]?.number!,
                    validation_radio: data[i]?.validation_radio!,
                    speedLimit: data[i].speedLimit
                });
            }
        }
        setTableData(dataTable);
    }

    return (
        <>
            {tableData.length > 0 && (
                <Table
                    size="small"
                    rowClassName={(record) => record}
                    pagination={false}
                    dataSource={tableData}
                    columns={columns}
                />
            )}
        </>
    )
}

export default TableLegendType;