import React from 'react'
import { Popover } from 'antd'
import './legendType.css'
import * as constant from '../../constants/colorConstants'
import { center } from '@turf/turf'

interface PopupTypes {
    type: string
    color: string
    colorMap?: ColorMap
}
const popup: PopupTypes[] = [
    {
        type: 'Waypoints',
        color: '#3498db',
        colorMap: {
            default: '#52a447',
            colorEntry: constant.COLOR_ZONE_ENTRY_WAY,
            colorExit: constant.COLOR_ZONE_EXIT_WAY,
        },
    },
    {
        type: 'Speed Zones',
        color: '#FF69B4',
        colorMap: {
            colorEntry: constant.COLOR_ZONE_ENTRY,
            colorExit: constant.COLOR_ZONE_EXIT,
        },
    },
    {
        type: 'Neutralizations',
        color: '#77dd77',
        colorMap: {
            colorEntry: constant.COLOR_ZONE_ENTRY_NEU,
            colorExit: constant.COLOR_ZONE_EXIT_NEU,
        },
    },
    {
        type: 'Track Files',
        color: '#ff6961',
    },
]

interface Props {
    section: ResultData | ResultData[] | undefined
    legendTypeClick: (type: string, value: AreaPoints[], color: ColorMap, name: string, index: number) => void
}
export const LegendType = ({ section, legendTypeClick }: Props) => {
    let countOf: Record<string, AreaPoints[][]> = {}
    if (section) {
        if (Array.isArray(section)) {
            const updatedSection: ResultData[] = section
            updatedSection.map((e: ResultData, index: number) => {
                e.analysis.map((value: Analysis) => {
                    if (index === 0) {
                        countOf[value.type] = []
                    }
                    const response = value.data
                    countOf[value.type].push(response)
                })
            })
        } else {
            const updatedSection: Analysis[] = section.analysis.filter(
                (e: Analysis, index: number) => e.type !== 'track_files'
            )
            updatedSection.map((value: Analysis) => {
                countOf[value.type] = []
                const response = value.data
                countOf[value.type].push(response)
            })
        }
    }

    Object.keys(countOf).map((res, index) => {
        if (countOf[res].length > 1) {
            countOf[res] = [countOf[res].flat()]
        }
        countOf[res].map((e: AreaPoints[]) => {
            e.map((e2: AreaPoints) => {
                if (res !== 'track_files') {
                    e2.superType = res
                }
            })
        })
    })
    return (
        <>
            {Object.keys(countOf).map((res, index) => {
                {
                    return countOf[res].map((e: AreaPoints[]) => {
                        const shouldShowPopover =
                            e.filter((x: AreaPoints) => {
                                return (
                                    res === 'waypoints' || res === 'track_files' || x.type === 'DZ' || x.type === 'FNEU'
                                )
                            }).length > 0
                        if (shouldShowPopover) {
                            return (
                                <Popover content={popup[index].type} color={popup[index].color} key={index}>
                                    <span
                                        key={index}
                                        onClick={() => {
                                            if (res !== 'track_files')
                                                legendTypeClick(res, e, popup[index].colorMap!, res, index)
                                        }}
                                        style={{
                                            backgroundColor: popup[index].color,
                                            width: '30px',
                                            height: '16px',
                                        }}
                                        className="legendItem "
                                    >
                                        {
                                            e.filter((x: AreaPoints) => {
                                                return (
                                                    res === 'waypoints' ||
                                                    res === 'track_files' ||
                                                    x.type === 'DZ' ||
                                                    x.type === 'FNEU'
                                                )
                                            }).length
                                        }
                                    </span>
                                </Popover>
                            )
                        }
                    })
                }
            })}
        </>
    )
}
