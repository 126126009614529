import { Input } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'

/**
 * @param defaultValue Default Value
 * @param x Remaining props that take the component from the parent component
 */
interface PropsInputDateTime {
    defaultValue: any
    timezone: string
    [x: string]: any
}

/**
 * Custom input for the use of datetime
 * @param {PropsInputDateTime} object Object to contruct the component
 * @returns
 */
const CustomInputDateTime = ({ defaultValue, timezone, ...restProps }: PropsInputDateTime) => {
    const [value, setValue] = useState(defaultValue)
    const [valueUnixTime, setValueUnixTime] = useState(defaultValue)
    useEffect(() => {
        setValue(
            moment(defaultValue * 1000)
                .tz(timezone)
                .format('DD-MM-YYYY HH:mm:ss')
        )
        setValueUnixTime(
            moment(defaultValue * 1000)
                .tz(timezone)
                .unix()
        )
    }, [defaultValue])

    useEffect(() => {
        if (value && /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4} (0\d|1\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(value)) {
            setValueUnixTime(moment.tz(value, 'DD-MM-YYYY HH:mm:ss', timezone).unix())
            restProps.onChange(moment.tz(value, 'DD-MM-YYYY HH:mm:ss', timezone).unix())
        } else {
            restProps.onChange(null)
        }
    }, [value])

    return (
        <Input
            className="w-full"
            size="small"
            {...restProps}
            value={value}
            onChange={event => {
                setValue(event.target.value)
            }}
        />
    )
}
export default CustomInputDateTime
