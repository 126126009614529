import * as XLSX from 'xlsx'
import { formatUT, secondsToPrettyformat, timeUtcToHHMMSS } from '../../utils'
import type { ExcelData, NeutralizationExcelRow } from '../../types/excel'

export const neutralizationExcelData = (info: NeutralizationResult[], timezone: string): ExcelData[] => {
    var neutralizationExcel: NeutralizationExcelRow[] = []

    for (let index = 0; index < info.length; index++) {
        const participantResult = info[index].data

        for (let j = 0; j < participantResult.results.length; j++) {
            const zoneResult = participantResult.results[j]

            if (zoneResult.diffTime) {
                neutralizationExcel.push({
                    Participant: info[index].number,
                    'Zone name': zoneResult.init.name,
                    'Entry time': timeUtcToHHMMSS(zoneResult.entryTimeUtc!, timezone),
                    'Exit time': timeUtcToHHMMSS(zoneResult.exitTimeUtc!, timezone),
                    'Total time': formatUT(zoneResult.exitTimeUtc! - zoneResult.entryTimeUtc!),
                    'Infringement (s)': secondsToPrettyformat(zoneResult.lack),
                })
            } else {
                neutralizationExcel.push({
                    Participant: info[index].number,
                    'Zone name': zoneResult.init.name,
                })
            }
        }
    }

    const speedWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(neutralizationExcel)

    const excelData: ExcelData = {
        worksheet: speedWorksheet,
        sheetName: 'Speed Report',
    }

    return [excelData]
}
