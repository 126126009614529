import * as XLSX from 'xlsx'
import { timeUtcToHHMMSS } from '../../utils'
import type { ExcelData, SpeedExcelRow, WaypointsExcelRow } from '../../types/excel'

export const waypointExcelData = (info: WaypointsResult[]) => {
    var waypointExcel: WaypointsExcelRow[] = []

    let wpUnchecked: any = []
    let wpChecked: any = []
    let OutTimeWpChecked: any = []
    let OutTimeWpUnchecked: any = []

    for (const result of info) {
        if (result.data.waypoints.length > 0) {
            for (let areaPoint of result.areaPoints) {
                if (!areaPoint.unfinished) {
                    if (!areaPoint.matched) {
                        if (areaPoint.matchedGps) {
                            wpChecked.push(areaPoint.name)
                        } else {
                            wpUnchecked.push(areaPoint.name)
                        }
                    } else {
                        wpChecked.push(areaPoint.name)
                    }
                } else {
                    if (!areaPoint.matched) {
                        if (areaPoint.matchedGps) {
                            OutTimeWpChecked.push(areaPoint.name)
                        } else {
                            OutTimeWpUnchecked.push(areaPoint.name)
                        }
                    } else {
                        OutTimeWpChecked.push(areaPoint.name)
                    }
                }
            }
            waypointExcel.push({
                Participant: result.number,
                'WpChecked Count': wpChecked.length,
                'WpChecked Name': wpChecked.toString(),
                'WpUnchecked Count': wpUnchecked.length,
                'WpUnchecked Name': wpUnchecked.toString(),
                'OutTimeWpCheked Count': OutTimeWpChecked.length,
                'OutTimeWpCheked name': OutTimeWpChecked.toString(),
                'OutTimeWpUnCheked Count': OutTimeWpUnchecked,
                'OutTimeWpUnCheked Name': OutTimeWpUnchecked.toString(),
            })

            wpUnchecked = []
            wpChecked = []
            OutTimeWpUnchecked = []
            OutTimeWpChecked = []
        }
    }

    const waypointWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(waypointExcel)

    const excelData: ExcelData = {
        worksheet: waypointWorksheet,
        sheetName: 'Waypoints Report',
    }

    return [excelData]
}
