import { Form, InputNumber, Tooltip } from 'antd'

const initialValueTolerance = 140
const initialValueMinDistance = 100

/**
 * Different components of the form that need the opposite direction to work
 * @returns Different form items
 */
const BaseSpecificOppositeForm = () => {
    return (
        <>
            <Form.Item
                name={['threshold', 'opposite']}
                label={
                    <div>
                        <Tooltip title="Radius of the maximum radius that the participant can leave the track in meters">
                            Radius
                        </Tooltip>
                    </div>
                }
                className="mb-0"
                initialValue={25}
            >
                <InputNumber className=" w-full" min={1} defaultValue={25} precision={0}></InputNumber>
            </Form.Item>
            <Form.Item
                name="tolerance"
                className="m-0 w-full"
                label={
                    <>
                        <Tooltip title="Tolerance that is allowed to detect when degrees of difference to be considered as opposite direction.">
                            {' '}
                            Tolerance (º)
                        </Tooltip>
                    </>
                }
                initialValue={initialValueTolerance}
            >
                <InputNumber className="w-full" min={1} defaultValue={initialValueTolerance} max={360} precision={0} />
            </Form.Item>
            <Form.Item
                name="minDistance"
                className="m-0 w-full"
                label={
                    <>
                        <Tooltip title="Minimum distance in meters for the path of the participant to be considered as opposite direction">
                            {' '}
                            Min Distance
                        </Tooltip>
                    </>
                }
                initialValue={initialValueMinDistance}
            >
                <InputNumber className="w-full" min={1} defaultValue={initialValueMinDistance} precision={0} />
            </Form.Item>
        </>
    )
}
export default BaseSpecificOppositeForm
