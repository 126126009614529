import VectorLayer from 'ol/layer/Vector';
import StrokeStyle from 'ol/style/Stroke';
import FillStyle from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import Style from 'ol/style/Style';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';
import * as olSphere from 'ol/sphere';
import * as olInteraction from 'ol/interaction';
import { Map, Feature } from 'ol';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';

const BearingCalculator = (map: Map, source: VectorSource<Feature<Geometry>>) => {

    let actionButton : any;
    let tooltipElement : any;
    let tooltip : any;
    let bearingInteraction : any;
    let listener : any;
    const bearingSetActionButton = (button : any) => {
        actionButton = button;
    }

    const handleDrawEnd = () => {
        if (actionButton) {
            actionButton.current.handlerClick();
        }
    }
    const bearingStop = () => {
        if (tooltipElement) {
            tooltipElement.parentElement.removeChild(tooltipElement);
            tooltipElement = null;
        }

        if (bearingInteraction !== undefined) {
            map.removeInteraction(bearingInteraction);
        }

        if (listener !== undefined) {
            unByKey(listener);
        }
    }

    const bearingStart = (button : any) => {
        bearingStop();
        bearingSetActionButton(button)

        bearingInteraction = new olInteraction.Draw({
            source: source,
            type: 'LineString',
            maxPoints: 2,
            style: new Style({
                fill: new FillStyle({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new StrokeStyle({
                    color: 'rgba(0, 0, 0, 0.5)',
                    lineDash: [10, 10],
                    width: 2
                }),
                image: new CircleStyle({
                    radius: 5,
                    stroke: new StrokeStyle({
                        color: 'rgba(0, 0, 0, 0.7)'
                    }),
                    fill: new FillStyle({
                        color: 'rgba(255, 255, 255, 0.2)'
                    })
                })
            })
        });

        map.addInteraction(bearingInteraction);

        createTooltip();

        bearingInteraction.on('drawstart', (evt: { feature: any; coordinate: any; }) => {
           const sketch = evt.feature;

            let tooltipCoord = evt.coordinate;

            listener = sketch.getGeometry().on('change', (evt: { target: any; }) => {
                let geom = evt.target;

                const coords = geom.flatCoordinates;

                let degrees = 90 - angle(coords[0], coords[1], coords[2], coords[3]);
                if (degrees < 0) {
                    degrees += 360;
                }

                let output = Math.floor(degrees) + "º";
                tooltipCoord = geom.getLastCoordinate();

                tooltipElement.innerHTML = output;
                tooltip.setPosition(tooltipCoord);
            });
        });

        bearingInteraction.on('drawend', () => {
            handleDrawEnd()
        });
    }

    const createTooltip =() => {
        if (tooltipElement) {
            tooltipElement.parentNode.removeChild(tooltipElement);
        }
        tooltipElement = document.createElement('div');
        tooltipElement.className = 'tooltip tooltip-measure';
        tooltip = new Overlay({
            element: tooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center'
        });
        map.addOverlay(tooltip);
    }

    return {
        bearingStart,
        bearingStop,
        bearingSetActionButton
    };
}

function angle(cx: number, cy: number, ex: number, ey: number) {
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    //if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
}

export default BearingCalculator;
