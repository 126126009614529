import { createContext } from 'react'

export const defaultMapSettings: RallyMap = {
    areaPoints : [],
    connections : {},
    customTracks : [],
    disconnectedGpsTrack : {
        main : [],
        secondary : []
    },
    highlightedPoints : [{},{}],
    participantTracks : []
}

// Creates a context which receives the data
export const RallyMapContext = createContext<RallyMap>(defaultMapSettings)