import React from 'react'
import { oppositeExcel } from '../../../excelReport/oppositeExcel'
import { Button } from 'antd'
interface Props {
    timezone: string
    info: OppositeResult[]
    text: string
    disabled: boolean
}
const OppositeDownloader = ({ info, text, timezone, disabled }: Props) => {
    return (
        <Button
            onClick={() => {
                oppositeExcel(info, timezone)
            }}
            disabled={disabled}
        >
            {text}
        </Button>
    )
}

export default OppositeDownloader
