import * as sortingfunctions from '../sortingfunctions'

export const defaultSortings: SortingObject[] = [
    {
        name: 'Partipant number (ascending)',
        sorting: sortingfunctions.sortByNumber,
    },
    {
        name: 'Partipant number (descending)',
        sorting: (a: Results[]) => sortingfunctions.sortByNumber(a, false),
    },
    {
        name: 'ID (ascending)',
        sorting: sortingfunctions.sortById,
    },
    {
        name: 'ID (descending)',
        sorting: (a: Results[]) => sortingfunctions.sortById(a, false),
    },
]
