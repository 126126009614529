import VectorLayer from 'ol/layer/Vector';
import StrokeStyle from 'ol/style/Stroke';
import FillStyle from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import Style from 'ol/style/Style';
import Observable from 'ol/Observable';
import Overlay from 'ol/Overlay';
import * as olSphere from 'ol/sphere';
import * as olInteraction from 'ol/interaction';
import { useEffect, useState } from 'react';
import { Map, Feature } from 'ol';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';



let measureTooltipElement : any
let measureTooltip : any
let measureInteraction : any = null;
const MeasurementsController = (map: Map, source: VectorSource<Feature<Geometry>>) : any => {

    const _formatLength = (line : any, units : string) => {
        let length = olSphere.getLength(line);
        let output;

        if (units === 'km') {
            if (length > 100) {
                output = (Math.round(length / 1000 * 100) / 100) +
                    ' ' + 'km';
            } else {
                output = (Math.round(length * 100) / 100) +
                    ' ' + 'm';
            }
        } else {
            let miles = ((length / 1000 * 100) / 100) * 0.621371
            output = miles.toFixed(2) + ' ' + 'mi';
        }
        return output;
    };

    const measureStop = () => {
        if (measureInteraction !== null) {
            map.removeInteraction(measureInteraction);
            map.removeOverlay(measureTooltip)
        }
    };

    const measureStart = () => {
        measureStop();
        measureInteraction = new olInteraction.Draw({
            source: source,
            type: 'LineString',
            style: new Style({
                fill: new FillStyle({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new StrokeStyle({
                    color: 'rgba(0, 0, 0, 0.5)',
                    lineDash: [10, 10],
                    width: 2
                }),
                image: new CircleStyle({
                    radius: 5,
                    stroke: new StrokeStyle({
                        color: 'rgba(0, 0, 0, 0.7)'
                    }),
                    fill: new FillStyle({
                        color: 'rgba(255, 255, 255, 0.2)'
                    })
                })
            })
        })

        map.addInteraction(measureInteraction);

        createMeasureTooltip();

        let listener;
        measureInteraction.on('drawstart', (evt :any) => {
            const sketch = evt.feature;

            let tooltipCoord = evt.coordinate;

            listener = sketch.getGeometry().on('change', (evt:any) => {
                let geom = evt.target;
                let output = _formatLength(geom, 'km');
                tooltipCoord = geom.getLastCoordinate();
                    measureTooltipElement.innerHTML = output;
                    measureTooltip.setPosition(tooltipCoord);
            });
        });
    };

    const createMeasureTooltip = () => {
        if (measureTooltipElement) {
            measureTooltipElement.parentNode.removeChild(measureTooltipElement);
        }

        const newMeasureTooltipElement = document.createElement('div');
        newMeasureTooltipElement.className = 'tooltip tooltip-measure';
        measureTooltipElement = (newMeasureTooltipElement);
        
        const newMeasureTooltip = new Overlay({
            element: newMeasureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        });
        measureTooltip =(newMeasureTooltip);

        map.addOverlay(newMeasureTooltip);
    };

    useEffect(() => {
        return () => {
            measureStop();
        };
    }, []);

    return {
        measureStart,
        measureStop,
    };
};

export default MeasurementsController;
