import { useEffect, useState, type Key } from 'react'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import TrackCard from '../../components/ParticipantCard/TrackCard/trackCard'
import '../../app.css'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import HeaderData from '../../components/dataHeader'
import { defaultSortings } from '../../constants/sortingConstants'
import { byDistance, sortByDistance } from './matchTrackFunctions'
import { useResultsEffect } from '../../hooks/useResults'

interface Props {
    results: MatchTrackResult[]
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>
}
const MatchtrackView = ({ results, setSpeedGraphic, setRallyMap }: Props) => {
    const [selectedResult, setSelectedResult] = useState<MatchTrackResult | null>(null)
    const [filteredResults, setFilteredResults] = useState<MatchTrackResult[]>(results)
    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)

    const sortings: SortingObject[] = [
        ...defaultSortings,
        {
            name: 'Tracked distance (ascending)',
            sorting: sortByDistance,
        },
        {
            name: 'Tracked distance (descending)',
            sorting: (a: MatchTrackResult[]) => sortByDistance(a, false),
        },
    ]

    const [sorting, setSorting] = useState<(resultA: Results, resultB: Results) => number>(() => sortings[0].sorting)

    const applyFilters = (sortedResults: MatchTrackResult[]) => {
        setFilteredResults([...sortedResults])
    }

    useResultsEffect(results, sorting, [], applyFilters)

    return (
        <div className="flex h-full flex-col">
            <div className="m-2 ">
                <HeaderData
                    defaultSorting={sortings[0]}
                    filteredComponentByZone={null}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filterComponents={[]}
                ></HeaderData>
            </div>
            <div className="h-full flex-1 overflow-y-auto p-2">
                {filteredResults.map((result: MatchTrackResult, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <TrackCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: MatchTrackResult) => setSelectedResult(result)}
                                />
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MatchtrackView
