import { Checkbox, Divider, Select } from 'antd'
import { Header } from 'antd/es/layout/layout'

interface Props {
    sortings: SortingObject[]
    onChangeSorting: (event: any) => void
    filteredComponentByZone: SelectComponent | null
    filterComponents: ComponentCheckbox[]
    defaultSorting: SortingObject
}
const HeaderData = ({
    sortings,
    onChangeSorting,
    filteredComponentByZone,
    filterComponents,
    defaultSorting: sorting,
}: Props) => {
    const selectOptions = sortings.map(e => {
        return {
            label: e.name,
            value: e.sorting,
        }
    })
    return (
        <div className="flex w-full flex-col rounded-lg bg-neutral-100/50 p-0 backdrop-blur-3xl ">
            <div className=" flex w-full flex-row p-2 align-top ">
                <span className="self-center  text-nowrap leading-none">Sort By</span>
                <Select
                    size="small"
                    defaultValue={sorting.name}
                    className="ml-4 mr-4 w-full flex-1"
                    onChange={e => {
                        onChangeSorting(e)
                    }}
                    options={selectOptions}
                />
            </div>
            {filteredComponentByZone !== null && (
                <div className="m-2 leading-none">
                    <Select
                        size="small"
                        defaultValue={filteredComponentByZone.value}
                        className=" w-full "
                        onChange={e => {
                            filteredComponentByZone.onChange(e)
                        }}
                        options={filteredComponentByZone.values}
                    />
                </div>
            )}
            <div className="flex flex-row p-2">
                {filterComponents.map((e: ComponentCheckbox, index: number) => {
                    return (
                        <Checkbox
                            key={`${index}-${e.name}`}
                            className="flex-1"
                            onChange={e.function}
                            checked={e.checked}
                        >
                            <div className="text-nowrap text-[12px]">{e.name}</div>
                        </Checkbox>
                    )
                })}
            </div>
            <div className="mx-2 pt-1">
                <Divider className="m-0 bg-neutral-300" />
            </div>
        </div>
    )
}

export default HeaderData
