import { defaultSettings } from '../contexts/settingsContext'
import { useState, useEffect } from 'react'


const getStorageSettings = (settingsKey: string, defaultSettings: Settings) => {
    // Try to get settings from memory
    const loadedSettingsString: string | null = localStorage.getItem(settingsKey)
    if (loadedSettingsString) {
        const loadedSettings = JSON.parse(loadedSettingsString)

        return loadedSettings
    }

    // Loads the default settings in case of error
    return defaultSettings
}


export const useSettings = (settings: Settings) => {
    const [value, setValue] = useState(() => {
        // Try to get settings in case of error loads the default
        return getStorageSettings('settings', defaultSettings)
    })

    useEffect(() => {
        if (value === undefined) return
        localStorage.setItem('settings', JSON.stringify(value))
    }, [settings, value])

    return [value, setValue]
}