import { saveExcel } from "./tools/saveExcel";
import * as XLSX from 'xlsx'
import { excelDataToWorkbook } from "./tools/excelDataToWorkbook";
import type { ExcelData } from "../types/excel";
import { customZoneExcelData } from "./excelData/customZoneExcelData";


export const customZoneExcel = (info: CustomZoneResult[], timezone: string) => {
    const title: string = "custom-report"
    const data: ExcelData[] = customZoneExcelData(info, timezone);
    const workbook: XLSX.WorkBook = excelDataToWorkbook(title, data)

    saveExcel(title, workbook);
}