import { saveExcel } from "./tools/saveExcel";
import * as XLSX from 'xlsx'
import { excelDataToWorkbook } from "./tools/excelDataToWorkbook";
import type { ExcelData } from "../types/excel";
import { speedZoneExcelData } from "./excelData/speedZoneExcelData";


export const speedZoneExcel = (info: SpeedZoneResult[], timezone: string) => {
    const title: string = "speed-report"
    const data: ExcelData[] = speedZoneExcelData(info, timezone);
    const workbook: XLSX.WorkBook = excelDataToWorkbook(title, data)

    saveExcel(title, workbook);
}