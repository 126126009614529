import * as turf from '@turf/turf'


export default class MatchTrackHandler {
    analyzer:any ;
    section: Section;
    excludedWaypointRanges: any;

    
    constructor(analyzer: any, section: Section, excludedWaypointRanges: any) {
        this.analyzer = analyzer;
        this.section = section;
        this.excludedWaypointRanges = excludedWaypointRanges;
    }


    getAreaPoints(resultInfo: any) {
        const unmatchedChunks = resultInfo.unmatchedChunks;
        let areaPoints = [];

        for (let unmatchedChunk of unmatchedChunks) {
            if (unmatchedChunk.points[unmatchedChunk.points.length / 2]) {
                if (unmatchedChunk.disabled === true) {
                    continue;
                };
                areaPoints.push({
                    name: 'Unmatched trackpoints',
                    coords: [unmatchedChunk.points[unmatchedChunk.points.length / 2][0], unmatchedChunk.points[unmatchedChunk.points.length / 2][1]],
                    radius: 0,
                    color: '#ff0000',
                    buttons: [
                        {
                            name: 'Disable',
                            onClick: () => this._addExclusionPoints(
                                unmatchedChunk.points[0][3], unmatchedChunk.points[unmatchedChunk.points.length - 1][3]),
                        },
                    ],
                });
            }
        }

        return areaPoints;
    }

    getLines(resultInfo: any) {
        const unmatchedChunks = resultInfo.unmatchedChunks;

        let tracks = [];
        for (let unmatchedChunk of unmatchedChunks) {
            if (unmatchedChunk.disabled === true) {
                continue;
            };

            tracks.push(unmatchedChunk.points);
        }

        return tracks;
    }

    excludeOutliers(resultInfo: any, excludedRanges: any) {
        const subpaths = resultInfo.unmatchedChunks;

        for (let subpath of subpaths) {
            for (let excludedRange of excludedRanges) {
                if (this._match(subpath.points, excludedRange)) {
                    subpath.disabled = true;
                }
            }
        }
    }

    setUnmatchedTrackRanges(resultInfo: any) {
        const subpaths = resultInfo.unmatchedChunks;
        const INDEX_POSITION = 3;
        for (let subpath of subpaths) {
            const pathIndexes = subpath.points.map((point: any[]) => point[INDEX_POSITION]);
            subpath.range = this._getDistancesAtEdges(this._getEdges(pathIndexes));
        }
    }

    _addExclusionPoints(firstIndex: number, lastIndex: number) {
        let newExcludedWaypointRanges = [...this.excludedWaypointRanges];
        newExcludedWaypointRanges.push([firstIndex, lastIndex]);

        const results = this.analyzer.getResults();

        const process = (results: MatchTrackResult[]) => {
            for (let result of results) {
                this.excludeOutliers(result.data, newExcludedWaypointRanges);

                result.areaPoints = this.getAreaPoints(result.data),
                    result.customTracks = this.getLines(result.data);
            }

            return results;
        }

        this.analyzer.onExcludedWaypointRanges(newExcludedWaypointRanges);
        this.analyzer.onResults(process(results));
    }

    _getEdges(indexes: number[]) {
        let firstIndex = indexes[0];
        let lastIndex = indexes[indexes.length - 1];

        if (firstIndex !== 0) {
            firstIndex -= 1;
        }

        if (lastIndex !== this.section.track.length - 2) {
            lastIndex += 1;
        }

        return [firstIndex, lastIndex];
    }

    _getDistancesAtEdges(edges: number[]) {
        const subtrackAtEdgeStart = this.section.track.slice(0, edges[0] + 1);
        const subtrackAtEdgeEnd = this.section.track.slice(0, edges[1] + 1);
        let distanceStart, distanceEnd;

        if (subtrackAtEdgeStart.length === 1) {
            distanceStart = 0;
        } else {
            distanceStart = turf.length(turf.lineString(subtrackAtEdgeStart))
        }

        distanceEnd = turf.length(turf.lineString(subtrackAtEdgeEnd));

        return [distanceStart, distanceEnd];
    }

    _match(track: any, excludedRange: any) {
        const INDEX_POSITION = 3;
        const MARGINS_POINTS = 1;

        const startsAfter = track[0][INDEX_POSITION] >= excludedRange[0] - MARGINS_POINTS;
        const endsBefore = track[track.length - 1][INDEX_POSITION] <= excludedRange[1] + MARGINS_POINTS;

        return startsAfter && endsBefore;
    }


}