import * as constants from '../../constants/colorConstants';
import * as api from '../../outtrack/rallyrestapi';

export default class SpeedZoneHandler {
    async getAnalysisSpecificInfo(rallyId : number, section : Section, token : string) {
        const sectionId = section.section;
        const waypointsResp = await api.getSpeedZoneWaypointsForTrack(rallyId, sectionId, token);
        const data = waypointsResp.data.event.data;
        return data
    }
}
