import axios from 'axios'

export async function getWaypointsForTrack(rallyId: number, trackId: number, token: string) {
    const response = await axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/waypoints/${rallyId}/${trackId}.json`,
        params: {
            token: token,
            type: 'waypoints',
        },
    })

    if (response && response.data.event.data) {
        const responseWaypoints: WaypointAPI[] = response.data.event.data
        const waypoints: Waypoint[] = responseWaypoints.map((waypoint: any) => {
            const newWaypoint: Waypoint = {
                adminLink: '',
                links: {} as Record<string, string>,
                bearing: waypoint.bearing,
                blocked: Boolean(waypoint.blocked),
                coordinate: [waypoint.longitude, waypoint.latitude],
                firstUnfinished: false,
                id: waypoint.id,
                latitude: waypoint.latitude,
                longitude: waypoint.longitude,
                matched: false,
                matchedGps: false,
                name: waypoint.name,
                number: waypoint.number,
                timeMatched: -1,
                type: waypoint.type,
                unfinished: false,
                validation_radio: waypoint.validation_radio,
            }

            return newWaypoint
        })

        return waypoints
    }

    return [] as Waypoint[]
}
