import React, { ReactNode } from "react";
import { MenuProps, Button, Dropdown } from "antd";
import { Coordinate } from "ol/coordinate";
import { CopyOutlined } from "@ant-design/icons";
import { copyCoordsToClipboard } from "../../../utils";

interface Props {
  coords: Coordinate;
  children: ReactNode;
}

/**
 * Function that receives an array of coordinates and converts them to latitude and longitude, creates a context menu with a button to copy them.
 * @param coords Unformatted coordinate array
 * @param children Children in which you want to activate the context menu
 * @returns HTML code with the Dropdown
 */

const ContextMenu = ({ coords: coords, children }: Props) => {
  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a onClick={(e) => copyCoordsToClipboard(coords)}>
          <CopyOutlined /> Copy Coords
        </a>
      ),
    }
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['contextMenu']} overlayStyle={{ minWidth: '135px' }}>
      {children}
    </Dropdown>
  );
};

export default ContextMenu;