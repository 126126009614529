import { Button } from 'antd'
import { waypointsTimeExcel } from '../../../excelReport/waypointsTimeExcel'
interface Props {
    info: WaypointsResult[]
    text: string
    disabled: boolean
    timezone : string
}
const WaypointsTimeDownloader = ({ info, text, disabled ,timezone}: Props) => {
    return (
        <Button
            onClick={() => {
                waypointsTimeExcel(info,timezone)
            }}
            disabled={disabled}
        >
            {text}
        </Button>
    )
}

export default WaypointsTimeDownloader
