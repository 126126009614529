import React, { ReactNode, useEffect, useState } from 'react'
import { Select, Popover, Spin, Button } from 'antd'
import { LegendType } from '../LegendType'
import './header.css'
import { FaChartLine } from 'react-icons/fa'
import { FaSlidersH } from 'react-icons/fa'
import { SettingsMenu } from './MenuSettings'

interface Props {
    name: string
    rallyId: number
    other_subrallies: OtherSubRallies[]
    legendType: ResultData[]
    sections: SectionsAPI | undefined
    token: string
    changeId: (value: string) => void
    legendTypeClick: (type: string, value: AreaPoints[], color: ColorMap, name: string, index: number) => void
    timezone: string
    loading: boolean
    onToggleFormVisibility: () => void
    onToggleSpeedInfoVisibility: () => void
    settings: Settings
    setSettings: (settings: Settings) => void
}
const Header = ({
    other_subrallies,
    changeId,
    legendType,
    legendTypeClick,
    loading,
    name,
    onToggleSpeedInfoVisibility,
    rallyId,
    sections,
    timezone,
    token,
    settings,
    setSettings,
}: Props) => {
    const [maxOptionLabelWidth, setMaxOptionLabelWidth] = useState<number>(0)

    useEffect(() => {
        calculateMaxOptionLabelWidth()
    }, [])

    useEffect(() => {
        calculateMaxOptionLabelWidth()
    }, [other_subrallies])

    const calculateMaxOptionLabelWidth = () => {
        const maxLabelWidth = other_subrallies.reduce((maxWidth: number, res: { nombre: string }) => {
            const labelWidth = getLabelWidth(res.nombre)
            return labelWidth > maxWidth ? labelWidth : maxWidth
        }, getLabelWidth(name))
        setMaxOptionLabelWidth(maxLabelWidth)
    }

    const getLabelWidth = (label: string) => {
        const span = document.createElement('span')
        span.textContent = label
        document.body.appendChild(span)
        const width = span.offsetWidth
        document.body.removeChild(span)
        return width
    }
    const handlerSelectChange = (value: string) => {
        changeId(value)
    }

    let refreshElement
    const selectWidth = maxOptionLabelWidth + 32
    if (loading) {
        refreshElement = (
            <div className="tail-item">
                <Spin />
            </div>
        )
    }

    return (
        <div className="header bg-black">
            <div className="header-start">
                <Select
                    disabled={loading}
                    style={{
                        width: selectWidth,
                    }}
                    options={[
                        {
                            value: rallyId,
                            label: name,
                        },
                        ...other_subrallies.map((res: OtherSubRallies, index: number) => ({
                            value: res.id,
                            label: res.nombre,
                            key: `option-${index}`,
                        })),
                    ]}
                    defaultValue={name}
                    onChange={handlerSelectChange}
                />

                <Button onClick={onToggleSpeedInfoVisibility}>
                    <FaChartLine size={22} />
                </Button>
                <LegendType section={legendType} legendTypeClick={legendTypeClick} />
            </div>
            <div className="header-finish">
                {refreshElement}
                <div className="header-timezone">{timezone}</div>
                <SettingsMenu settings={settings} setSettings={setSettings} />
            </div>
        </div>
    )
}

export default Header
