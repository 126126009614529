import type { FormInstance } from 'antd'
import { createContext } from 'react'

interface BaseFormContextProps {
    form: FormInstance<any> | null
}

export const defaultForm: BaseFormContextProps = {
    form: null,
}

// Creates a context which receives the data
export const SpecificFormContext = createContext<BaseFormContextProps>(defaultForm)
