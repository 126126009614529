import * as XLSX from 'xlsx'
import { timeUtcToHHMM } from '../../utils'
import type { ExcelData, OppositeExcelRow } from '../../types/excel'

export const oppositeExcelData = (info: OppositeResult[], timezone: string) => {
    var oppositeExcel: OppositeExcelRow[] = []
    const replays: string[] = []

    info.forEach((participantResult: OppositeResult) => {
        const infoParticipant: [] = participantResult.data.info
        infoParticipant.forEach((item: any) => {
            const distance: string = `${(Math.round(item.trajectoryDistance / 10) * 10).toFixed(0)} m`
            replays.push(item.replay)
            oppositeExcel.push({
                Participant: item.participantNumber,
                SS: item.sectionName,
                Time: timeUtcToHHMM(item.startTimeUtc, timezone),
                Distance: distance,
                Replay: 'PLAY',
            })
        })
    })

    const oppositeWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(oppositeExcel)
    for (let index = 0; index < replays.length; index++) {
        oppositeWorksheet[`E${index + 2}`].l = {
            Target: replays[index],

            Tooltip: 'Replay opposite direction',
        }
    }

    const excelData: ExcelData = {
        worksheet: oppositeWorksheet,
        sheetName: 'Opposite Report',
    }

    return [excelData]
}
