import { oppositeExcelData } from "./excelData/oppositeExcelData";
import { saveExcel } from "./tools/saveExcel";
import * as XLSX from 'xlsx'
import { excelDataToWorkbook } from "./tools/excelDataToWorkbook";
import type { ExcelData } from "../types/excel";


export const oppositeExcel = (info: OppositeResult[], timezone: string) => {
    const title: string = "opposite-report"

    const data: ExcelData[] = oppositeExcelData(info, timezone);
    const workbook: XLSX.WorkBook = excelDataToWorkbook(title, data)

    saveExcel(title, workbook);
}