import { Form, InputNumber, Radio, Tooltip } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { SpecificFormContext } from '../../../contexts/specificFormContext'
import SelectCriterias from '../../CustomSelectCriterias'

const initialValueTolerance = 0
const initialValuePenalty = 0
const initialValueMinSec = 1
const initialValueToleranceRadiusNumber = 0

interface Props {
    criterias: CriteriaAPI[]
}

/**
 * Different components of the form that need the speed zone to work
 * @returns Different form items
 */
const BaseSpecificSpeedZonesForm = ({ criterias }: Props) => {
    const [toleranceRadius, setToleranceRadius] = useState(true)
    const [criteria, setCriteria] = useState(null)
    const form = useContext(SpecificFormContext)

    useEffect(() => {
        if (form) {
            setToleranceRadius(form.form?.getFieldValue('toleranceRadius'))
        }
    }, [form.form?.getFieldValue('toleranceRadius')])

    useEffect(() => {
        if (form) {
            setCriteria(form.form?.getFieldValue('speedZoneCriteria'))
        }
    }, [form.form?.getFieldValue('speedZoneCriteria')])

    return (
        <>
            <div className="flex flex-row space-x-2">
                <Form.Item name="toleranceRadius" label="Tolerance 90m" initialValue={toleranceRadius}>
                    <Radio.Group onChange={e => setToleranceRadius(e.target.value)} value={toleranceRadius}>
                        <Radio.Button value={true}>On</Radio.Button>
                        <Radio.Button value={false}>Off</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <div className='hidden'>
                    <Form.Item name="toleranceRadiusNumber" label="" initialValue={null}></Form.Item>
                </div>
                {/* <Form.Item name="toleranceRadiusNumber" label="" initialValue={initialValueToleranceRadiusNumber}>
                        {!toleranceRadius && (
                            <InputNumber
                                className=""
                                min={0}
                                defaultValue={initialValueToleranceRadiusNumber}
                                precision={0}
                            ></InputNumber>
                        )}
                    </Form.Item> */}
            </div>
            <Form.Item name={'speedZoneCriteria'} label="Criterias" className="mb-0" initialValue={criterias[0]}>
                <SelectCriterias criterias={criterias} criteriaUnique={criteria} />
            </Form.Item>
            <Form.Item
                name={['threshold', 'speed']}
                label={
                    <div>
                        <Tooltip title="Radius of the maximum radius that the participant can have to reduce the speed">
                            Radius
                        </Tooltip>
                    </div>
                }
                className="mb-0"
                initialValue={25}
            >
                <InputNumber className=" w-full" min={1} defaultValue={90} precision={0}></InputNumber>
            </Form.Item>
            <Form.Item
                name="penaltyTolerance"
                className="m-0 w-full"
                label={
                    <>
                        <Tooltip title="Tolerance in kilometers per hour allowed in order not to be penalized by the speed limit">
                            {' '}
                            Tolerance
                        </Tooltip>
                    </>
                }
                initialValue={initialValueTolerance}
            >
                <InputNumber className="w-full" min={0} defaultValue={initialValueTolerance} precision={0} />
            </Form.Item>
            <Form.Item
                name="consecSecs"
                className="m-0 w-full"
                label={
                    <>
                        <Tooltip title="Minimum number of seconds you must exceed the speed limit continuously">
                            {' '}
                            Min sec
                        </Tooltip>
                    </>
                }
                initialValue={initialValueMinSec}
            >
                <InputNumber className="w-full" min={1} defaultValue={initialValueMinSec} precision={0} />
            </Form.Item>
        </>
    )
}
export default BaseSpecificSpeedZonesForm
