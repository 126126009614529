import React from 'react';
import PropTypes from 'prop-types';

import './map-legend.css';

interface Props {
    className : string,
    values : LegendData[]
}

const MapLegend = ({className,values} : Props) => {
    if (!values) {
        return null;
    }

    return (
        <div className={className}>

        {values.map (data => {
            return (
                <div key={data.label} className='map-legend-container'>
                    {data.bar ? (
                    <div className='legend-bar' style={{backgroundColor:data.color}}>
                    </div>

                    ) :
                    (
                    <div className='legend-marker fa fa-map-marker' style={{color:data.color}}>
                    </div>
                    )}
                    <div className='legend-data'>
                       {data.label}
                    </div>
                </div>
            )
        })}
            
        </div>
    );
}

export default MapLegend;
