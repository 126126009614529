import { Form, InputNumber, Tooltip } from 'antd'

const initialValueThreshold = 25

/**
 * Different components of the form that need the match track to work
 * @returns Different form items
 */
const BaseSpecificMatchForm = () => {
    return (
        <>
            <Form.Item
                name={['threshold', 'match']}
                label={
                    <div>
                        <Tooltip title="Radius of the maximum radius that the participant can leave the track in meters">
                            Radius
                        </Tooltip>
                    </div>
                }
                className="mb-0"
                initialValue={initialValueThreshold}
            >
                <InputNumber
                    className=" w-full"
                    min={1}
                    defaultValue={initialValueThreshold}
                    precision={0}
                ></InputNumber>
            </Form.Item>
        </>
    )
}
export default BaseSpecificMatchForm
