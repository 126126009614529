import { useEffect, useState } from 'react'
import { defaultMapSettings } from '../contexts/mapContext'

export function useSelectedResultEffect(
    selectedResult: Results | null,
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>,
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>

) {
    useEffect(() => {
        if (selectedResult) {
            const rallyMapData = {
                areaPoints: selectedResult.areaPoints,
                participantTracks: selectedResult.participantTracks,
                connections: selectedResult.connections,
                customTracks: selectedResult.customTracks,
                disconnectedGpsTrack: selectedResult.disconnectedGpsTrack,
                highlightedPoints: selectedResult.highlightedPoints,
            }
            const speedGraphicData : SpeedGraphic = {
                speedRanges : [],
                tracks : selectedResult.participantTracks
            }
            setRallyMap(rallyMapData)
            setSpeedGraphic(speedGraphicData)
        }
    }, [selectedResult, setRallyMap])
}
