import { Popover } from 'antd'

type WaypointsFailedProps = {
    numberOfWpNotValidated: number
    numberOfWpValidated: number
}
const WaypointsFailed = ({ numberOfWpNotValidated, numberOfWpValidated }: WaypointsFailedProps) => {
    const getWpFailed = (numberOfWp: number, className: string, type: 'validated' | 'notValidated') => {
        return (
            <Popover
                content={
                    <span>
                        There are {numberOfWp} failed waypoints {type === 'validated' ? 'validated' : 'not validated'}
                    </span>
                }
                trigger={'hover'}
            >
                <div className={`${className} mr-2 h-full w-10 self-center  text-center text-xs font-bold`}>
                    {numberOfWp}
                </div>
            </Popover>
        )
    }

    return (
        <>
            {numberOfWpNotValidated > 0 && getWpFailed(numberOfWpNotValidated, 'bg-red-400', 'notValidated')}
            {numberOfWpValidated > 0 && getWpFailed(numberOfWpValidated, 'bg-red-600', 'validated')}
        </>
    )
}
export default WaypointsFailed
