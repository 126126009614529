import * as turf from '@turf/turf'
import { byNumber } from '../../sortingfunctions'
import { getFirstIndexInArea, getLastIndexInArea } from '../../outtrack/common'

export const sortByStartTime = (results: NeutralizationResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byStartTime({ number: a.number, results: a.data.results }, { number: b.number, results: b.data.results })
    )
    return ascending ? sortedResults : sortedResults.reverse()
}

const byStartTime = (resultsA: SortingNeu, resulstB: SortingNeu) => {
    if (resultsA.results.length > 0 && resulstB.results.length > 0) {
        for (let index = resultsA.results.length - 1; index >= 0; index--) {
            const a = resultsA.results[index]
            const b = resulstB.results[index]

            if (a.entryTimeUtc !== null && b.entryTimeUtc !== null) {
                return a.entryTimeUtc > b.entryTimeUtc ? 1 : -1
            } else if (a.entryTimeUtc !== null && b.entryTimeUtc === null) {
                return -1
            } else if (a.entryTimeUtc === null && b.entryTimeUtc !== null) {
                return 1
            }
        }
    }
    if (resultsA.results.length > 0 && resulstB.results.length < 1) {
        return 1
    } else if (resultsA.results.length < 1 && resulstB.results.length > 0) {
        return -1
    }
    return byNumber(resultsA.number, resulstB.number)
}

export const searchClosestPointBackwardsWithStops = (path: number[][], fromIndex: number, targetPoint: number[]) => {
    // TODO: comprobar caso en que no hay puntos después del wp
    const LON = 0
    const LAT = 1
    let currentPathIndex = fromIndex

    if (currentPathIndex === undefined) {
        return undefined
    }

    let lastDistance = turf.distance(turf.point(path[currentPathIndex--]), turf.point(targetPoint))
    let currentDistance

    while (
        currentPathIndex >= 0 &&
        (currentDistance = turf.distance(turf.point(path[currentPathIndex]), turf.point(targetPoint))) < lastDistance &&
        !(
            path[currentPathIndex - 1][LON] === path[currentPathIndex][LON] &&
            path[currentPathIndex - 1][LAT] === path[currentPathIndex][LAT]
        )
    ) {
        lastDistance = currentDistance
        currentPathIndex--
    }

    if (currentPathIndex + 1 === fromIndex) {
        // last point is before target point
        return currentPathIndex + 1
    }

    return currentPathIndex + 1
}

export const searchClosestPointForwardWithStops = (path: number[][], fromIndex: number, targetPoint: number[]) => {
    // TODO: comprobar caso en que no hay puntos después del wp
    const LON = 0
    const LAT = 1

    let currentPathIndex = fromIndex

    if (currentPathIndex === undefined) {
        return undefined
    }

    let lastDistance = turf.distance(turf.point(path[currentPathIndex++]), turf.point(targetPoint))
    let currentDistance

    while (
        currentPathIndex < path.length - 1 &&
        (currentDistance = turf.distance(turf.point(path[currentPathIndex]), turf.point(targetPoint))) < lastDistance &&
        !(
            path[currentPathIndex][LON] === path[currentPathIndex - 1][LON] &&
            path[currentPathIndex][LAT] === path[currentPathIndex - 1][LAT]
        )
    ) {
        lastDistance = currentDistance
        currentPathIndex++
    }

    return currentPathIndex - 1
}

export const getZoneBounds = (zone: NeutralizationZone, participantTrack: number[][]) => {
    let entryIndex: number | null | undefined = getFirstIndexInArea(
        zone.init.longitude,
        zone.init.latitude,
        zone.init.validation_radio,
        participantTrack,
        0,
        zone.init.bearing
    )
    if (entryIndex !== null) {
        const targetPoint = [zone.init.longitude, zone.init.latitude]
        entryIndex = searchClosestPointForwardWithStops(participantTrack, entryIndex, targetPoint)
    }

    let exitIndex: number | null | undefined = getLastIndexInArea(
        zone.end.longitude,
        zone.end.latitude,
        zone.end.validation_radio,
        participantTrack,
        0,
        zone.end.bearing
    )
    if (exitIndex) {
        if (participantTrack[exitIndex + 1]) {
            if (exitIndex !== null) {
                const targetPoint = [zone.end.longitude, zone.end.latitude]
                exitIndex = searchClosestPointBackwardsWithStops(participantTrack, exitIndex, targetPoint)
            }
        } else {
            exitIndex = null
        }
    }
    if (entryIndex && exitIndex && entryIndex > exitIndex) {
        exitIndex = getLastIndexInArea(
            zone.end.longitude,
            zone.end.latitude,
            zone.end.validation_radio,
            participantTrack,
            entryIndex,
            zone.end.bearing
        )
    }
    return [entryIndex, exitIndex]
}

export const getMostReasonableZoneBounds = (
    zone: NeutralizationZone,
    participantTrackA: number[][],
    participantTrackB: number[][],
    trackTypeFilterSelected: string
): [number | null, number | null, string] => {
    const [entryIndexA, exitIndexA] = getZoneBounds(zone, participantTrackA)
    const [entryIndexB, exitIndexB] = getZoneBounds(zone, participantTrackB)
    switch (trackTypeFilterSelected) {
        case 'main':
            if (entryIndexA !== null || exitIndexA !== null) {
                return [entryIndexA!, exitIndexA!, 'primary']
            }
            break

        case 'secondary':
            if (entryIndexB !== null || exitIndexB !== null) {
                return [entryIndexB!, exitIndexB!, 'secondary']
            }
            break

        case 'both':
            if (entryIndexA !== null && exitIndexA !== null && entryIndexB !== null && exitIndexB !== null) {
                const diffTimeA = participantTrackA[exitIndexA!][2] - participantTrackA[entryIndexA!][2]
                const diffTimeB = participantTrackB[exitIndexB!][2] - participantTrackB[entryIndexB!][2]
                if (diffTimeB > diffTimeA) {
                    return [entryIndexB!, exitIndexB!, 'secondary']
                } else {
                    return [entryIndexA!, exitIndexA!, 'primary']
                }
            } else if (entryIndexA === null || exitIndexA === null) {
                return [entryIndexB!, exitIndexB!, 'secondary']
            } else if (entryIndexB === null || exitIndexB === null) {
                return [entryIndexA!, exitIndexA!, 'primary']
            }
            break
    }
    return [null, null, '']
}
