import React, {
    LegacyRef,
    memo,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useContext,
} from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import * as olControl from 'ol/control'
import * as olExtent from 'ol/extent'
import { fromLonLat, toLonLat, transform } from 'ol/proj'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import GroupLayer from 'ol/layer/Group'
import OsmSource from 'ol/source/OSM'
import TileLayer from 'ol/layer/Tile'
import BingMapsSource from 'ol/source/BingMaps'
import Overlay from 'ol/Overlay'
import Feature, { FeatureLike } from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import IconStyle from 'ol/style/Icon'
import TextStyle from 'ol/style/Text'
import StrokeStyle from 'ol/style/Stroke'
import FillStyle from 'ol/style/Fill'
import Circle from 'ol/geom/Circle'
import LineString from 'ol/geom/LineString'
import ActionButton from './actionButton'
import MeasurementsController from './measurementsController'
import BearingCalculator from './bearingCalculator'
import ToggleActionButton from './toggleActionButton'
import FLAG_ICON from '../../res/flag.png'
import ContextMenu from './components/contextMenu'

import { isEqual, clone } from 'lodash'
import 'moment-timezone'
import moment from 'moment'
import './map.css'
import { getPointResolution } from 'ol/proj'
import { copyCoordsToClipboard, copyText, getCoordsString, getCoordString } from '../../utils'
import { FaArrowRight, FaEraser, FaGlasses, FaGlobe, FaLayerGroup, FaRuler } from 'react-icons/fa6'
import { Coordinate } from 'ol/coordinate'
import { Geometry } from 'ol/geom'
import _ from 'lodash'
const DETAILS_ZOOM_THRESHOLD = 18
const BING_KEY =
    'KhyBEfwPzN9kgkihhgH0~yMVcpbPka4IrdCzm5Lqr4A~AnNTgcxgJzjUhNTTrRyWBH7bTgI70r0oqBj8VhSgYeVbOM3lufCLltghvaSmWj8y'
import LOADING_GIF from '../../res/loading.gif'
import NO_HIGHLIGHT_CONNECTED_UNKNOWN_DOT from '../../res/no-highlight-connected-unknown-dot.png'
import NO_HIGHLIGHT_CONNECTED_DOT from '../../res/no-highlight-connected-dot.png'
import NO_HIGHLIGHT_NOT_CONNECTED_DOT from '../../res/no-highlight-not-connected-dot.png'
import NO_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW from '../../res/no-highlight-connected-unknown-arrow.png'
import NO_HIGHLIGHT_CONNECTED_ARROW from '../../res/no-highlight-connected-arrow.png'
import NO_HIGHLIGHT_NOT_CONNECTED_ARROW from '../../res/no-highlight-not-connected-arrow.png'

import SECONDARY_NO_HIGHLIGHT_CONNECTED_UNKNOWN_DOT from '../../res/secondary-no-highlight-connected-unknown-dot.png'
import SECONDARY_NO_HIGHLIGHT_CONNECTED_DOT from '../../res/secondary-no-highlight-connected-dot.png'
import SECONDARY_NO_HIGHLIGHT_NOT_CONNECTED_DOT from '../../res/secondary-no-highlight-not-connected-dot.png'
import SECONDARY_NO_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW from '../../res/secondary-no-highlight-connected-unknown-arrow.png'
import SECONDARY_NO_HIGHLIGHT_CONNECTED_ARROW from '../../res/secondary-no-highlight-connected-arrow.png'
import SECONDARY_NO_HIGHLIGHT_NOT_CONNECTED_ARROW from '../../res/secondary-no-highlight-not-connected-arrow.png'

import GENERIC_HIGHLIGHT_CONNECTED_UNKNOWN_DOT from '../../res/highlight-connected-unknown-dot.png'
import GENERIC_HIGHLIGHT_CONNECTED_DOT from '../../res/highlight-connected-dot.png'
import GENERIC_HIGHLIGHT_NOT_CONNECTED_DOT from '../../res/highlight-not-connected-dot.png'
import GENERIC_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW from '../../res/generic-highlight-connected-unknown-arrow.png'
import GENERIC_HIGHLIGHT_CONNECTED_ARROW from '../../res/generic-highlight-connected-arrow.png'
import GENERIC_HIGHLIGHT_NOT_CONNECTED_ARROW from '../../res/generic-highlight-not-connected-arrow.png'
import SECONDARY_GENERIC_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW from '../../res/secondary-generic-highlight-connected-unknown-arrow.png'
import SECONDARY_GENERIC_HIGHLIGHT_CONNECTED_ARROW from '../../res/secondary-generic-highlight-connected-arrow.png'
import SECONDARY_GENERIC_HIGHLIGHT_NOT_CONNECTED_ARROW from '../../res/secondary-generic-highlight-not-connected-arrow.png'

import MAX_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_DOT from '../../res/max-speed-highlight-connected-unknown-dot.png'
import MAX_SPEED_HIGHLIGHT_CONNECTED_DOT from '../../res/max-speed-highlight-connected-dot.png'
import MAX_SPEED_HIGHLIGHT_NOT_CONNECTED_DOT from '../../res/max-speed-highlight-not-connected-dot.png'
import MAX_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW from '../../res/max-speed-highlight-connected-unknown-arrow.png'
import MAX_SPEED_HIGHLIGHT_CONNECTED_ARROW from '../../res/max-speed-highlight-connected-arrow.png'
import MAX_SPEED_HIGHLIGHT_NOT_CONNECTED_ARROW from '../../res/max-speed-highlight-not-connected-arrow.png'

import MIN_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_DOT from '../../res/min-speed-highlight-connected-unknown-dot.png'
import MIN_SPEED_HIGHLIGHT_CONNECTED_DOT from '../../res/min-speed-highlight-connected-dot.png'
import MIN_SPEED_HIGHLIGHT_NOT_CONNECTED_DOT from '../../res/min-speed-highlight-not-connected-dot.png'
import MIN_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW from '../../res/min-speed-highlight-connected-unknown-arrow.png'
import MIN_SPEED_HIGHLIGHT_CONNECTED_ARROW from '../../res/min-speed-highlight-connected-arrow.png'
import MIN_SPEED_HIGHLIGHT_NOT_CONNECTED_ARROW from '../../res/min-speed-highlight-not-connected-arrow.png'
import { RallyMapContext } from '../../contexts/mapContext'

const participantIcons = {
    primary: {
        no_highlight: {
            connected_unknown: {
                dot: NO_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: NO_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: NO_HIGHLIGHT_CONNECTED_DOT,
                arrow: NO_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: NO_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: NO_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
        genericHighlight: {
            connected_unknown: {
                dot: GENERIC_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: GENERIC_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: GENERIC_HIGHLIGHT_CONNECTED_DOT,
                arrow: GENERIC_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: GENERIC_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: GENERIC_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
        maxSpeedHighlight: {
            connected_unknown: {
                dot: MAX_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: MAX_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: MAX_SPEED_HIGHLIGHT_CONNECTED_DOT,
                arrow: MAX_SPEED_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: MAX_SPEED_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: MAX_SPEED_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
        minSpeedHighlight: {
            connected_unknown: {
                dot: MIN_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: MIN_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: MIN_SPEED_HIGHLIGHT_CONNECTED_DOT,
                arrow: MIN_SPEED_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: MIN_SPEED_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: MIN_SPEED_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
    },
    secondary: {
        no_highlight: {
            connected_unknown: {
                dot: SECONDARY_NO_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: SECONDARY_NO_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: SECONDARY_NO_HIGHLIGHT_CONNECTED_DOT,
                arrow: SECONDARY_NO_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: SECONDARY_NO_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: SECONDARY_NO_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
        genericHighlight: {
            connected_unknown: {
                dot: GENERIC_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: SECONDARY_GENERIC_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: GENERIC_HIGHLIGHT_CONNECTED_DOT,
                arrow: SECONDARY_GENERIC_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: GENERIC_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: SECONDARY_GENERIC_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
        maxSpeedHighlight: {
            connected_unknown: {
                dot: MAX_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: MAX_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: MAX_SPEED_HIGHLIGHT_CONNECTED_DOT,
                arrow: MAX_SPEED_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: MAX_SPEED_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: MAX_SPEED_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
        minSpeedHighlight: {
            connected_unknown: {
                dot: MIN_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_DOT,
                arrow: MIN_SPEED_HIGHLIGHT_CONNECTED_UNKNOWN_ARROW,
            },
            connected: {
                dot: MIN_SPEED_HIGHLIGHT_CONNECTED_DOT,
                arrow: MIN_SPEED_HIGHLIGHT_CONNECTED_ARROW,
            },
            not_connected: {
                dot: MIN_SPEED_HIGHLIGHT_NOT_CONNECTED_DOT,
                arrow: MIN_SPEED_HIGHLIGHT_NOT_CONNECTED_ARROW,
            },
        },
    },
}

interface Props {
    dataKey: number
    organizerTracks: Section[]
    rally: number
    type: string
    detailsZoom: boolean
    showConnections: boolean
    clickCounter: number
    timezone: string
    rallyLongitude: number
    rallyLatitude: number
}
let currentParticipantTracks: any = []
let currentCustomTracks: any = []
let cuurentDisconnectedTrack: any = []
let currentOrganizerTracks: any = []

const RallyMap = forwardRef(
    (
        {
            clickCounter,
            dataKey,
            organizerTracks,
            rally,
            rallyLatitude,
            rallyLongitude,
            showConnections,
            timezone,
            type,
            detailsZoom,
        }: Props,
        ref: any
    ) => {
        const rallyMap = useContext(RallyMapContext)
        const mapRef = useRef<any>(ref)
        const popRef = useRef<any>()
        const buttonRef = useRef<any>()
        const copiedPopupRef = useRef<any>()
        const organizerTracksPreviusRef = useRef(organizerTracks)
        const participantTracksPreviusRef = useRef(rallyMap.participantTracks)
        const areaPointsPreviusRef = useRef(rallyMap.areaPoints)
        const disconnectedGpsTrackPreviusRef = useRef(rallyMap.disconnectedGpsTrack)
        const customTracksPreviusRef = useRef(rallyMap.customTracks)
        const [map, setMap] = useState<Map>(new Map())

        const measurementsController = MeasurementsController(map, new VectorSource())
        const { measureStart, measureStop } = measurementsController as {
            measureStart: () => void
            measureStop: () => void
        }

        const { bearingStart, bearingStop } = BearingCalculator(map, new VectorSource())

        const [measurementsLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [disconnectedLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [sectionsLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [participantTracksLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [circumferencesLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [areaPointsLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [disconnectedGpsTrackLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [customTracksLayer] = useState(new VectorLayer({ source: new VectorSource() }))
        const [participantPointsLayer] = useState(
            new VectorLayer({
                source: new VectorSource(),
                visible: false,
            })
        )
        const [pointerLayer] = useState(new VectorLayer({ source: new VectorSource() }))

        let timeoutRendering: any
        let currentLayerIndex = 1

        const [contextMenuCoords, setContextMenuCoords] = useState<Coordinate>([])
        const [groupLayers, setGroupLayers] = useState<GroupLayer[]>()
        const [currentZoom, setCurrentZoom] = useState<number | undefined>(5)

        const [pointer, setPointer] = useState<Feature<Point> | null>(null)
        const [copiedPopup, setCopiedPopup] = useState<Overlay | null>(null)
        const [popup, setPopup] = useState<Overlay | null>(null)

        useEffect(() => {
            const mapInstance = new Map({
                target: mapRef.current,
                controls: [],
                view: new View({
                    center: fromLonLat([rallyLongitude, rallyLatitude]),
                    zoom: 11,
                }),
            })

            const popupInstance = new Overlay({
                element: popRef.current,
                positioning: 'bottom-center',
                stopEvent: true,
                offset: [0, 0],
            })

            const copiedPopupInstance = new Overlay({
                element: copiedPopupRef.current,
                positioning: 'bottom-center',
                stopEvent: true,
                offset: [0, 0],
            })

            setMap(mapInstance)
            setPopup(popupInstance)
            setCopiedPopup(copiedPopupInstance)

            return () => {
                if (mapInstance) {
                    mapInstance.setTarget(undefined)
                }
                if (popupInstance) {
                    popupInstance.setPosition(undefined)
                }
                if (copiedPopupInstance) {
                    copiedPopupInstance.setPosition(undefined)
                }
            }
        }, [])

        useEffect(() => {
            const layerGroupOSM = new GroupLayer({
                layers: [
                    new TileLayer({
                        source: new OsmSource(),
                    }),
                    circumferencesLayer,
                    sectionsLayer,
                    participantTracksLayer,
                    customTracksLayer,
                    participantPointsLayer,
                    disconnectedLayer,
                    areaPointsLayer,
                    pointerLayer,
                    disconnectedGpsTrackLayer,
                    measurementsLayer,
                ],
            })

            const layerGroupBing = new GroupLayer({
                layers: [
                    new TileLayer({
                        source: new BingMapsSource({
                            imagerySet: 'AerialWithLabels',
                            key: BING_KEY,
                        }),
                    }),
                    circumferencesLayer,
                    sectionsLayer,
                    participantTracksLayer,
                    customTracksLayer,
                    participantPointsLayer,
                    disconnectedLayer,
                    areaPointsLayer,
                    pointerLayer,
                    disconnectedGpsTrackLayer,
                    measurementsLayer,
                ],
            })
            const newGroupLayers: GroupLayer[] = [layerGroupOSM, layerGroupBing]
            setGroupLayers(newGroupLayers)

            map?.setLayerGroup(newGroupLayers[currentLayerIndex])

            map.getViewport().addEventListener('contextmenu', function (e) {
                e.preventDefault()
                setContextMenuCoords(map.getEventCoordinate(e))
            })

            map.on('click', e => {
                if (e.originalEvent.shiftKey) {
                    copyCoordsToClipboard(e.coordinate)
                } else {
                    let feature = map.forEachFeatureAtPixel(e.pixel, feature => feature)
                    if (feature && feature.getProperties().showPopup) {
                        _showPopup(feature, e.coordinate)
                    } else {
                        _hidePopup()
                    }
                }
            })

            map.on('moveend', e => {
                const zoom = map.getView().getZoom()
                setCurrentZoom(zoom)
            })
            _updateMap()
        }, [map])

        const _updateMap = () => {
            let changesApplied = _updateOrganizerTracks()
            changesApplied = _updateParticipantTracks() || changesApplied
            _updateCustomTracks()
            _updateAreaPoints()

            if (changesApplied) {
                setStandardMapView()
            }
        }
        useEffect(() => {
            if (rallyMap.disconnectedGpsTrack) {
                if (
                    JSON.stringify(rallyMap.disconnectedGpsTrack) !==
                    JSON.stringify(disconnectedGpsTrackPreviusRef.current)
                ) {
                    _updateDisconnectedTrack()
                }
                disconnectedGpsTrackPreviusRef.current = rallyMap.disconnectedGpsTrack
            }
        }, [rallyMap.disconnectedGpsTrack])

        useEffect(() => {
            if (popup !== null && copiedPopup !== null) {
                map.addOverlay(popup)
                map?.addOverlay(copiedPopup)
            }
        }, [popup])

        useEffect(() => {
            if (!detailsZoom && currentZoom) {
                if (currentZoom > DETAILS_ZOOM_THRESHOLD) {
                    participantPointsLayer.setVisible(true)
                } else if (currentZoom <= DETAILS_ZOOM_THRESHOLD) {
                    participantPointsLayer.setVisible(false)
                }
            }
        }, [currentZoom])

        useEffect(() => {
            if (participantPointsLayer.getVisible() !== detailsZoom) {
                participantPointsLayer.setVisible(detailsZoom)
            }
        }, [detailsZoom])

        useEffect(() => {
            if (JSON.stringify(rallyMap.areaPoints) !== JSON.stringify(areaPointsPreviusRef.current)) {
                _updateAreaPoints()
            }
            areaPointsPreviusRef.current = rallyMap.areaPoints
        }, [rallyMap.areaPoints])

        useEffect(() => {
            if (JSON.stringify(rallyMap.customTracks) !== JSON.stringify(customTracksPreviusRef.current)) {
                _updateCustomTracks()
            }
            customTracksPreviusRef.current = rallyMap.customTracks
        }, [rallyMap.customTracks])

        useEffect(() => {
            if (JSON.stringify(organizerTracks) !== JSON.stringify(organizerTracksPreviusRef.current)) {
                let changesApplied = _updateOrganizerTracks()
                if (changesApplied) {
                    setStandardMapView()
                    _updateAreaPoints()
                }
            }
            organizerTracksPreviusRef.current = organizerTracks
        }, [organizerTracks])

        useEffect(() => {
            if (JSON.stringify(rallyMap.participantTracks) !== JSON.stringify(participantTracksPreviusRef.current)) {
                let changesApplied = _updateParticipantTracks()
                if (changesApplied) {
                    setStandardMapView()
                }
            }
            participantTracksPreviusRef.current = rallyMap.participantTracks
        }, [rallyMap.participantTracks])

        useImperativeHandle(ref, () => ({
            updatePointer: updatePointer,
            updateSize: updateSize,
            _hidePopup: _hidePopup,
            findFeatureByName: findFeatureByName,
        }))

        const updateSize = () => {
            setTimeout(() => map.updateSize(), 0)
        }

        const _updateDisconnectedTrack = () => {
            if (rallyMap.disconnectedGpsTrack)  {
                const disconnectedGpsTrackInfo = [...rallyMap.disconnectedGpsTrack.main?.map((points: any) => ({
                    points,
                    info: {},
                })) , ... rallyMap.disconnectedGpsTrack.secondary?.map((points: any) => ({
                    points,
                    info: {},
                }))]
                return _updateTracks(
                    disconnectedGpsTrackLayer.getSource(),
                    disconnectedGpsTrackInfo,
                    cuurentDisconnectedTrack,
                    (trackToDrawInfo: { points: any }) => _getTrackFeature(trackToDrawInfo.points, '#FF8000FF', 5),
                    (removedEntry: any) => {},
                    (addedEntry: any) => {}
                )
            }
        }
        const _updateParticipantTracks = () => {
            if (rallyMap.participantTracks) {
                const participantTracksInfo = rallyMap.participantTracks.map((points: any, i: number) => ({
                    points,
                    info: {
                        participantTrackType: i == 0 ? 'primary' : 'secondary',
                        highlightedPoints:
                            rallyMap.highlightedPoints === undefined ? undefined : rallyMap.highlightedPoints[i],
                        connections: rallyMap.connections === undefined ? undefined : rallyMap.connections[i],
                    },
                }))
                participantTracksInfo.reverse()

                const tracksSource = participantTracksLayer.getSource()
                const pointsSource = participantPointsLayer.getSource()
                const disconnectedSource = disconnectedLayer.getSource()
                return _updateTracks(
                    tracksSource,
                    participantTracksInfo,
                    currentParticipantTracks,
                    (trackToDrawInfo: any) =>
                        _getTrackFeature(
                            trackToDrawInfo.points,
                            trackToDrawInfo.info.participantTrackType === 'primary' ? '#008000FF' : '#FF00FFFF',
                            5
                        ),
                    (removedEntry: any) => {
                        const participantTrackType = removedEntry.sourceInfo.info.participantTrackType
                        ;[pointsSource, disconnectedSource].forEach(source => {
                            source?.getFeatures().forEach(feature => {
                                if (feature.getProperties().tag === participantTrackType) {
                                    source.removeFeature(feature)
                                }
                            })
                        })
                    },
                    (addedEntry: any) => {
                        const disconnectedGroups = []
                        let currentGroup: any[] = []

                        const participantTrackType = addedEntry.sourceInfo.info.participantTrackType

                        addedEntry.sourceInfo.points.forEach((point: any, i: number) => {
                            let highlightType = addedEntry.sourceInfo.info.highlightedPoints[i]
                            if (highlightType === undefined) {
                                highlightType = 'no_highlight'
                            }

                            const projectedCoords = fromLonLat([point[0], point[1]])
                            const feature = new Feature(new Point(projectedCoords))

                            const bearingKey = point[3] > 5 ? 'arrow' : 'dot'

                            let connectionKey = 'connected_unknown'
                            let providerName = undefined
                            if (showConnections) {
                                connectionKey = 'not_connected'

                                const providers = addedEntry.sourceInfo.info.connections
                                for (let j = 0; j < providers.length; j++) {
                                    const provider = providers[j]

                                    if (provider.ut_ini <= point[2] && point[2] <= provider.ut_fin) {
                                        connectionKey = 'connected'
                                        providerName = provider.operador
                                        break
                                    }
                                }

                                if (connectionKey === 'not_connected') {
                                    currentGroup.push(point)
                                } else if (currentGroup.length) {
                                    disconnectedGroups.push(currentGroup)
                                    currentGroup = []
                                }
                            }

                            feature.setStyle(
                                new Style({
                                    image: new IconStyle({
                                        anchorXUnits: 'fraction',
                                        anchorYUnits: 'fraction',
                                        opacity: 1,
                                        src: (participantIcons as any)[participantTrackType][highlightType][
                                            connectionKey
                                        ][bearingKey],
                                        scale: bearingKey === 'dot' ? 1.5 : 1,
                                        rotation: (point[4] * Math.PI) / 180,
                                    }),
                                })
                            )

                            feature.setProperties({
                                showPopup: true,
                                longitude: point[0],
                                latitude: point[1],
                                timestamp: point[2],
                                index: i,
                                speed: point[3],
                                name: 'Participant trackpoint',
                                type: 'zone',
                                tag: participantTrackType,
                                providerName,
                            })

                            pointsSource?.addFeature(feature)
                        })

                        if (currentGroup.length) {
                            disconnectedGroups.push(currentGroup)
                        }

                        disconnectedGroups.forEach((group, i) => {
                            const style = new Style({
                                stroke: new StrokeStyle({
                                    color: '#000000FF',
                                    width: 5,
                                }),
                            })
                            const lineFeature = new Feature({
                                geometry: new LineString(group.map(coords => fromLonLat([coords[0], coords[1]]))),
                                name: '',
                            })

                            lineFeature.setStyle(style)
                            lineFeature.setProperties({
                                tag: participantTrackType,
                            })

                            disconnectedSource?.addFeature(lineFeature)
                        })
                    }
                )
            }
        }

        const updatePointer = (longitude: number, latitude: number, followPointer: number) => {
            if (!pointer) {
                const newPointer: Feature<Point> = new Feature(new Point(fromLonLat([longitude, latitude])))
                newPointer.setProperties({
                    showPopup: true,
                    name: 'Pointer',
                    latitude: latitude,
                    longitude: longitude,
                    type: 'marker',
                })
                newPointer.setStyle(_getMarkerStyle('#ffa500', '\uf063'))
                pointerLayer.getSource()?.addFeatures([newPointer])

                setPointer(newPointer)
            }

            if (pointer) {
                pointer?.setProperties({
                    latitude: latitude,
                    longitude: longitude,
                })

                const projCoords = fromLonLat([longitude, latitude])

                pointer?.setGeometry(new Point(projCoords))

                if (followPointer) {
                    const mapSize = map.getSize()
                    const mapExtent = map.getView().calculateExtent(mapSize)
                    const wTenth = (mapExtent[2] - mapExtent[0]) * 0.1
                    const hTenth = (mapExtent[3] - mapExtent[1]) * 0.1
                    const strictExtent = [
                        mapExtent[0] + wTenth,
                        mapExtent[1] + hTenth,
                        mapExtent[2] - wTenth,
                        mapExtent[3] - hTenth,
                    ]
                    if (
                        !(
                            strictExtent[0] <= projCoords[0] &&
                            projCoords[0] <= strictExtent[2] &&
                            strictExtent[1] <= projCoords[1] &&
                            projCoords[1] <= strictExtent[3]
                        )
                    ) {
                        setCenter(longitude, latitude)
                    }
                }
            }
        }

        function findFeatureByName(featureValueName: string) {
            let foundFeature = null
            map.getLayers().forEach(layer => {
                if (layer instanceof VectorLayer) {
                    const source = layer.getSource()
                    source!.forEachFeature(feature => {
                        if (feature.values_.name === featureValueName) {
                            foundFeature = feature
                        }
                    })
                }
            })
            if (foundFeature) {
                foundFeature = foundFeature as any
                _showPopup(foundFeature, foundFeature.geometryChangeKey_.target.flatCoordinates)
                map.getView().setCenter(foundFeature.geometryChangeKey_.target.flatCoordinates)
            }
        }

        const _showPopup = (feature: FeatureLike, projCoordinates: Coordinate) => {
            const properties = feature.getProperties()
            if (popRef.current && popup) {
                popRef.current.innerHTML = ''

                let title = document.createElement('b')
                title.appendChild(document.createTextNode(properties.name))
                popRef.current.appendChild(title)

                if (properties.adminLink !== undefined) {
                    popRef.current.appendChild(document.createTextNode(' ('))
                    const adminLink = document.createElement('a')
                    adminLink.setAttribute('href', properties.adminLink)
                    adminLink.setAttribute('target', '_blank')
                    adminLink.appendChild(document.createTextNode('Admin'))
                    popRef.current.appendChild(adminLink)
                    popRef.current.appendChild(document.createTextNode(')'))
                }

                popRef.current.appendChild(document.createElement('br'))
                popRef.current.appendChild(document.createTextNode(`Latitude: ${getCoordString(properties.latitude)}`))
                popRef.current.appendChild(document.createElement('br'))
                popRef.current.appendChild(
                    document.createTextNode(`Longitude: ${getCoordString(properties.longitude)}`)
                )

                if (properties.name2 !== undefined && properties.adminLink2 !== undefined) {
                    popRef.current.appendChild(document.createElement('hr'))
                    let title2 = document.createElement('b')
                    title2.appendChild(document.createTextNode(properties.name2))
                    popRef.current.appendChild(title2)
                    popRef.current.appendChild(document.createTextNode(' ('))
                    const adminLink2 = document.createElement('a')
                    adminLink2.setAttribute('href', properties.adminLink2)
                    adminLink2.setAttribute('target', '_blank')
                    adminLink2.appendChild(document.createTextNode('Admin'))
                    popRef.current.appendChild(adminLink2)
                    popRef.current.appendChild(document.createTextNode(')'))
                    popRef.current.appendChild(document.createElement('br'))
                    popRef.current.appendChild(
                        document.createTextNode(`Latitude: ${getCoordString(properties.latitude2)}`)
                    )
                    popRef.current.appendChild(document.createElement('br'))
                    popRef.current.appendChild(
                        document.createTextNode(`Longitude: ${getCoordString(properties.longitude2)}`)
                    )
                }
                if (properties.speedLimit !== undefined) {
                    popRef.current.appendChild(document.createElement('br'))
                    popRef.current.appendChild(document.createTextNode(`Speed limit: ${properties.speedLimit} km/h`))
                }
                if (properties.speed !== undefined) {
                    popRef.current.appendChild(document.createElement('br'))
                    popRef.current.appendChild(document.createTextNode(`Speed: ${properties.speed} km/h`))
                }

                if (properties.timestamp !== undefined) {
                    const tz = moment(properties.timestamp * 1000).tz(timezone)
                    const time = tz.format('HH:mm:ss')
                    const date = tz.format('YYYY-MM-DD')

                    popRef.current.appendChild(document.createElement('br'))

                    const divElement = document.createElement('div')
                    divElement.setAttribute('class', 'popup-tooltip')
                    divElement.appendChild(document.createTextNode(`Time: ${time}`))

                    const spanElement = document.createElement('span')
                    spanElement.setAttribute('class', 'popup-tooltiptext')
                    spanElement.appendChild(document.createTextNode(`Date: ${date}`))
                    divElement.appendChild(spanElement)

                    popRef.current.appendChild(divElement)
                }

                if (properties.providerName !== undefined) {
                    popRef.current.appendChild(document.createElement('br'))

                    const spanElement = document.createElement('span')
                    spanElement.appendChild(document.createTextNode(`Provider: ${properties.providerName}`))

                    popRef.current.appendChild(spanElement)
                }

                popRef.current.appendChild(document.createElement('br'))

                const buttons: any = properties.buttons === undefined ? [] : Array.from(properties.buttons)
                buttons.push({
                    name: 'Copy coords',
                    onClick: () => copyText(getCoordsString(properties.latitude, properties.longitude)),
                })

                for (const buttonEntry of buttons) {
                    const buttonElement = document.createElement('input')
                    buttonElement.type = 'button'
                    buttonElement.value = buttonEntry.name
                    buttonElement.onclick = buttonEntry.onClick
                    popRef.current.appendChild(buttonElement)
                }
                popup.getElement()!.style.display = 'block'
                popup.setPosition(projCoordinates)
            }
        }

        const _hidePopup = () => {
            if (popup !== null) {
                if (popup.getElement() !== null) {
                    popup.getElement()!.style.display = 'none'
                    popup.setPosition(undefined)
                }
            }
        }

        // const _getTracksIdToRemove = () => {
        //     let tracksToRemove = [];

        //     for (let trackIndex = 0; trackIndex < currentTracks.length; trackIndex++) {
        //         let found = false;

        //         for (let track of tracks) {
        //             if (isEqual(currentTracks[trackIndex].points, track.points)) {
        //                 found = true;
        //                 break;
        //             }
        //         }

        //         if (!found) {
        //             tracksToRemove.push(currentTracks[trackIndex].map_id);
        //         }
        //     }

        //     return tracksToRemove;
        // }
        const _updateOrganizerTracks = () => {
            const organizerTracksInfo = organizerTracks?.map((trackData: Section) => ({
                points: trackData.track,
                info: {
                    name: trackData.name,
                    adminLink: trackData.adminLink,
                },
            }))
            return _updateTracks(
                sectionsLayer.getSource(),
                organizerTracksInfo,
                currentOrganizerTracks,
                (trackToDrawInfo: { points: any }) => _getTrackFeature(trackToDrawInfo.points, '#0000FFFF', 10),
                (removedEntry: any) => {},
                (addedEntry: any) => {}
            )
        }
        // const _getTracksToDraw = () => {
        //     let tracksToDraw = [];

        //     for (let trackIndex = 0; trackIndex < tracks.length; trackIndex++) {
        //         let found = false;

        //         for (let track of currentTracks) {
        //             if (isEqual(tracks[trackIndex].points, track.points)) {
        //                 found = true;
        //                 break;
        //             }
        //         }

        //         if (!found) {
        //             tracksToDraw.push(tracks[trackIndex]);
        //         }
        //     }

        //     return tracksToDraw;
        // }

        const _toggleLayer = () => {
            map.setLayerGroup(groupLayers![++currentLayerIndex % groupLayers!.length])
        }

        const _createCircularZone = (center: Coordinate, radius: any, data: any) => {
            const radiusInMap = _calculateMapRadius(center, radius)
            const circle = new Circle(center, radiusInMap)
            const circleFeature = new Feature(circle)
            const htmlInfo = `
            <b> ${data.name} </b> <br />
            Latitude: ${getCoordString(data.coords[1])} <br />
            Longitude: ${getCoordString(data.coords[0])} <br />
            Radius: ${radius}`

            circleFeature.setProperties({
                popUpInfo: htmlInfo,
                type: 'zone',
            })

            return circleFeature
        }

        const _calculateMapRadius = (point: Coordinate, radius: number) => {
            const view = map.getView()
            const projection = view.getProjection()
            const resolutionAtEquator = view.getResolution()

            const pointResolution = getPointResolution(projection, resolutionAtEquator!, point)
            const resolutionFactor = resolutionAtEquator! / pointResolution

            return radius * resolutionFactor
        }

        const setCenter = (lon: number, lat: number) => {
            map.getView().setCenter(transform([lon, lat], 'EPSG:4326', 'EPSG:3857'))
        }

        const setZoom = (zoom: number) => {
            map.getView().setZoom(zoom)
        }

        const _getTrackFeature = (data: any, color: string, width: number) => {
            if (data) {
                const style = new Style({
                    stroke: new StrokeStyle({
                        color: color,
                        width: width,
                    }),
                })
                const lineFeature = new Feature({
                    geometry: new LineString(data.map((coords: number[]) => fromLonLat([coords[0], coords[1]]))),
                    name: 'Main track',
                })

                lineFeature.setStyle(style)

                return lineFeature
            }
        }

        const _getFlagStyle = () => {
            return new Style({
                image: new IconStyle({
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    opacity: 1,
                    src: FLAG_ICON,
                    rotation: (0 * Math.PI) / 180,
                }),
            })
        }

        const _getMarkerStyle = (color: string, hexIcon: string) => {
            return new Style({
                text: new TextStyle({
                    text: hexIcon,
                    font: 'normal 30px FontAwesome',
                    textBaseline: 'bottom',
                    fill: new FillStyle({
                        color: color,
                    }),
                }),
            })
        }

        const _updateAreaPoints = () => {
            if (rallyMap.areaPoints) {
                const areaPointsLayerSource = areaPointsLayer.getSource()
                const circumferencesLayerSource = circumferencesLayer.getSource()

                areaPointsLayerSource?.clear()
                circumferencesLayerSource?.clear()
                let previousArea: any = null
                let iconFeature: Feature<Geometry> | null = null
                for (let area of rallyMap.areaPoints) {
                    if (area.speed) {
                        area.speedLimit = area.speed === null ? undefined : area.speed
                    }
                    switch (area.superType) {
                        case 'speed_zones':
                            area.adminLink =
                                `https://web.anube.es/crono/grids/grid/${rally}` +
                                `/zonas_velocidad_tbl/edit/zonas_velocidad_tbl/${area.id}` +
                                `?keywords=zonas_velocidad_tbl.rally_id%3D"${rally}"`
                            break
                        case 'neutralizations':
                            area.adminLink =
                                `https://web.anube.es/crono/grids/grid/${rally}` +
                                `/zonas_neutralizadas_tbl/edit/zonas_neutralizadas_tbl/${area.id}` +
                                `?keywords=zonas_neutralizadas_tbl.rally_id="${rally}"`
                            break
                        case 'waypoints':
                            area.adminLink =
                                `https://web.anube.es/crono/grids/grid/${rally}` +
                                `/wp_tbl/edit/wp_tbl/${area.id}` +
                                `?keywords=wp_tbl.rally_id="${rally}"`
                            break
                        default:
                            break
                    }
                    const markerWithAsterisk = area.firstUnfinished
                    const textWithAsterisk = '\uf041' + '*'
                    const center = fromLonLat(area.coords)
                    if (
                        previousArea != null &&
                        previousArea.coords[0] == area.coords[0] &&
                        previousArea.coords[1] == area.coords[1]
                    ) {
                        areaPointsLayerSource?.removeFeature(iconFeature!)
                        iconFeature = new Feature(new Point(center))
                        iconFeature.setProperties({
                            name2: area.name,
                            adminLink2: area.adminLink,
                            showPopup: true,
                            longitude: previousArea.coords[0],
                            latitude: previousArea.coords[1],
                            name: previousArea.name,
                            // buttons: area.buttons,
                            type: 'marker',
                            adminLink: previousArea.adminLink,
                            latitude2: area.coords[1],
                            longitude2: area.coords[0],
                            speedLimit: area.speedLimit,
                        })
                        iconFeature.setStyle(_getMarkerStyle(area.color, textWithAsterisk))
                    } else {
                        iconFeature = new Feature(new Point(center))
                        iconFeature.setProperties({
                            showPopup: true,
                            longitude: area.coords[0],
                            latitude: area.coords[1],
                            speedLimit: area.speedLimit,
                            name: area.name,
                            // buttons: area.buttons,
                            type: 'marker',
                            adminLink: area.adminLink,
                        })
                        iconFeature.setStyle(
                            _getMarkerStyle(area.color, markerWithAsterisk ? textWithAsterisk : '\uf041')
                        )
                    }

                    areaPointsLayerSource?.addFeature(iconFeature)

                    let circularZone = _createCircularZone(center, area.radius, area)
                    circumferencesLayerSource?.addFeature(circularZone)
                    previousArea = area
                }

                currentOrganizerTracks?.forEach((trackEntry: any) => {
                    const points = trackEntry.sourceInfo.points
                    if (!points || points.length < 1) {
                        return
                    }
                    const firstPoint = points[0]
                    const center = fromLonLat([firstPoint[0], firstPoint[1]])

                    let iconFeature = new Feature(new Point(center))
                    iconFeature.setProperties({
                        showPopup: true,
                        longitude: firstPoint[0],
                        latitude: firstPoint[1],
                        name: trackEntry.sourceInfo.info.name,
                        adminLink: trackEntry.sourceInfo.info.adminLink,
                    })
                    iconFeature.setStyle(_getFlagStyle())
                    areaPointsLayerSource?.addFeature(iconFeature)
                })
            }
        }

        const _updateCustomTracks = () => {
            if (rallyMap.customTracks) {
                const customTracksInfo = rallyMap.customTracks.map((points: any) => ({
                    points,
                    info: {},
                }))

                return _updateTracks(
                    customTracksLayer.getSource(),
                    customTracksInfo,
                    currentCustomTracks,
                    (trackToDrawInfo: { points: any }) => _getTrackFeature(trackToDrawInfo.points, '#FF0000FF', 5),
                    (removedEntry: any) => {},
                    (addedEntry: any) => {}
                )
            }
        }

        const _updateTracks = (
            source: VectorSource<Feature<Geometry>> | null,
            wantedTracksInfo: any,
            currentTrackEntries: any,
            onCreateTrackFeature: (trackToDrawInfo: any) => Feature<LineString> | undefined,
            onRemoveTrackFeature: any,
            onEntryAdded: any
        ) => {
            let changesApplied = false
            let newTracksInfo = _.clone(wantedTracksInfo)
            for (let i = currentTrackEntries.length - 1; i >= 0; i--) {
                const currentTrackEntry = currentTrackEntries[i]

                let found = false

                for (let j = newTracksInfo.length - 1; j >= 0; j--) {
                    const newTrackInfo = newTracksInfo[j]

                    if (isEqual(currentTrackEntry.sourceInfo.points, newTrackInfo.points)) {
                        found = true
                        newTracksInfo.splice(j, 1)
                        break
                    }
                }
                if (!found) {
                    source?.removeFeature(currentTrackEntry.feature)
                    onRemoveTrackFeature(currentTrackEntry)
                    currentTrackEntries.splice(i, 1)
                    changesApplied = true
                }
            }
            newTracksInfo?.forEach((newTrackInfo: any) => {
                const feature = onCreateTrackFeature(newTrackInfo)
                const newEntry = {
                    feature,
                    sourceInfo: newTrackInfo,
                }
                if (feature) {
                    source?.addFeature(feature)
                }
                currentTrackEntries.push(newEntry)
                changesApplied = true

                onEntryAdded(newEntry)
            })

            return changesApplied
        }

        const setStandardMapView = () => {
            setStandardZoom()
            removePointer()
        }

        const setStandardZoom = () => {
            let allPoints: any[] = []
            ;[currentOrganizerTracks, currentParticipantTracks, currentCustomTracks].forEach(l => {
                l.forEach((trackEntry: any) => {
                    trackEntry.sourceInfo.points?.forEach((point: number[]) => {
                        allPoints.push(fromLonLat([point[0], point[1]]))
                    })
                })
            })

            if (allPoints.length) {
                let extent = olExtent.boundingExtent(allPoints)
                map.getView().fit(extent)
            } else {
                setCenter(rallyLongitude, rallyLatitude)
                map.getView().setZoom(8)
            }
        }

        const removePointer = () => {
            pointerLayer.getSource()?.clear()
            if (pointer !== null) {
                setPointer(null)
            }
        }

        return (
            <React.Fragment>
                <ContextMenu coords={contextMenuCoords}>
                    <div className="map  border-0 border-b border-solid border-neutral-300">
                        <div id="map" className={'map-widget '} ref={mapRef}></div>
                        {/* <div className={'map-widget ' + (loading ? '' : 'invisible')}>
                            <div className="map-widget-loading-text-area">
                                <img src={LOADING_GIF} alt="Loading..." />
                            </div>
                        </div> */}
                    </div>
                </ContextMenu>
                <div id="copied-popup" ref={copiedPopupRef} className="ol-popup">
                    {' '}
                </div>
                <div id="marker-popup" ref={popRef} className="ol-popup ol-popup-long"></div>

                <div className="action-buttons">
                    <ToggleActionButton
                        icon={FaRuler}
                        onActivateControl={() => measureStart()}
                        onDeactivateControl={() => measureStop()}
                    />

                    <ActionButton icon={FaEraser} onClick={() => measureStop()} />

                    <ActionButton icon={FaLayerGroup} onClick={() => _toggleLayer()} />

                    <ToggleActionButton
                        icon={FaArrowRight}
                        onActivateControl={() => bearingStart(buttonRef)}
                        onDeactivateControl={() => bearingStop()}
                        ref={buttonRef}
                    />

                    <ActionButton icon={FaGlobe} onClick={() => setStandardZoom()} />

                    {pointer !== null && (
                        <ActionButton
                            icon={FaGlasses}
                            onClick={() => {
                                if (pointer === null) {
                                    return
                                }

                                const pointerProperties = pointer.getProperties()

                                map.getView().setZoom(DETAILS_ZOOM_THRESHOLD)
                                setCenter(pointerProperties.longitude, pointerProperties.latitude)
                            }}
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }
)

export { RallyMap, DETAILS_ZOOM_THRESHOLD }
