/**
 * Function that orders string of numbers from smallest to biggest number
 * @param numberA First string to compare
 * @param numberB Second string to compare
 * @returns If both strings are not a number, look at the length of the string is larger, if numberA is longer it returns -1 otherwise 1
 * If both strings are a number, return the subtractions of numbers
 * In other cases if the fisrt string is a number it returns -1 otherwise 1
 */
export function byNumber(numberA: string, numberB: string) {
    // At least at the time of writing, "numbers" may not necessarily be numbers but also
    // strings such as "Org1". We sort the actual number strings numerically and the
    // other strings by ASCII code, and we give greater priority to the numeric participants
    if (isNaN(Number(numberA)) && isNaN(Number(numberB))) {
        return numberA.length < numberB.length ? -1 : 1
    } else if (!isNaN(Number(numberA)) && !isNaN(Number(numberB))) {
        return Number(numberA) - Number(numberB)
    } else {
        return isNaN(Number(numberA)) ? 1 : -1
    }
}

/**
 * Function that sorts the results by the function byNumer
 * @param results  Results of the report
 * @param ascending If sorting is ascending
 * @returns Return sorted results if is ascending otherwise sorte results reversed
 */
export const sortByNumber = (results: Results[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) => byNumber(a.number, b.number))
    return ascending ? sortedResults : sortedResults.reverse()
}

/**
 * Function that sorts the results by the function byId
 * @param results  Results of the report
 * @param ascending If sorting is ascending
 * @returns Return sorted results if is ascending otherwise sorte results reversed
 */
export const sortById = (results: Results[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) => byId(a.id, b.id))

    return ascending ? sortedResults : sortedResults.reverse()
}

/**
 * Function that orders numbers from smallest to biggest number
 * @param idA First number to compare
 * @param idB Second number to compare
 * @returns  Return 0 if the to 2 parameters are not a number
 * Return -1 if idA is not a number and idB is , if it is the other way around returns 1.
 * Default return the subtractions of ids
 */
export function byId(idA: number, idB: number) {
    if (isNaN(idA) && isNaN(idB)) {
        return 0
    }
    if (isNaN(idA) || isNaN(idB)) {
        return isNaN(idA) ? 1 : -1
    }
    return idA - idB
}
