import type { Rule } from 'antd/es/form'

export const DateTimeRules: Rule[] = [
    {
        validator: (_, value) => {
            if (value !== null) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Invalid DateTime'));
              }
        },
        message: 'Invalid DateTime',
    },
]
