import type { FormInstance } from 'antd'
import { createContext } from 'react'



export const defaultForm: any = {
    setSpecificData: null,
}

// Creates a context which receives the data
export const BaseFormContext = createContext<any>(defaultForm)
