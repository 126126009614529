import { saveExcel } from "./tools/saveExcel";
import * as XLSX from 'xlsx'
import { excelDataToWorkbook } from "./tools/excelDataToWorkbook";
import type { ExcelData } from "../types/excel";
import { waypointsTimeExcelData } from "./excelData/waypointsTimeExcelData";


export const waypointsTimeExcel = (info: WaypointsResult[],timezone : string) => {
    const title: string = "waypoints-time-report"
    const data: ExcelData[] = waypointsTimeExcelData(info,timezone);
    const workbook: XLSX.WorkBook = excelDataToWorkbook(title, data)

    saveExcel(title, workbook);
}