import React, { useContext, useEffect } from 'react'
const { useState } = React
import { Modal, Button, Select } from 'antd'
import { FaGear } from 'react-icons/fa6'

type Props = {
    settings: Settings
    setSettings: (settings: Settings) => void
}
/**
 * Component that create a menu with some fields
 * @returns SettingsMenu Component
 */
export const SettingsMenu = ({ settings, setSettings }: Props) => {
    // Manages if the modal is visible or not
    const [modalSettingsOpen, setModalSettingsOpen] = useState<boolean>(false)

    const [selectedValue, setSelectedValue] = useState<string>(settings.select)

    const handleOk = () => {
        if (selectedValue) {
            const newSettings: Settings = structuredClone(settings)
            newSettings.select = selectedValue
            setSettings(newSettings)
        }
        setModalSettingsOpen(false)
    }

    const handleCancel = () => {
        // If nothing is selected, set the default
        setSelectedValue(settings.select)
        setModalSettingsOpen(false)
    }

    return (
        <>
            {/* <Button type="text" onClick={() => setModalSettingsOpen(true)}>
                <FaGear size={22} color="white" />
            </Button> */}
            <Modal
                title="Configuration"
                style={{
                    top: 20,
                }}
                keyboard={true}
                okText="Save"
                open={modalSettingsOpen}
                onOk={() => handleOk()}
                onCancel={() => handleCancel()}
            >
                <div style={{ marginBottom: '10px' }}>
                    <span>Time Format : </span>
                    <Select
                        defaultValue={settings.select}
                        style={{ width: 140 }}
                        onChange={(value: string) => {
                            setSelectedValue(value)
                        }}
                        value={selectedValue}
                        options={[
                            { value: 'Option1', label: 'Option1' },
                            { value: 'Option2', label: 'Option2' },
                            { value: 'Option3', label: 'Option3' },
                            { value: 'Option4', label: 'Option4' },
                        ]}
                    />
                </div>
            </Modal>
        </>
    )
}
